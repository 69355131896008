import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Box, IconButton, Paper, Tab, Tabs, Tooltip, Typography, useTheme } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BalancesList from './BalancesList';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import ExpandIcon from '@mui/icons-material/Expand';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { BALANCES_STATE_UPDATE } from '../../redux/actionTypes/workspaceActions';
import { writeToLocalStorage } from '../../shared/helpers/localStorageInterface';
import { FETCH_CURRENCIES_INIT } from '../../redux/actionTypes/currenciesActionTypes';
import { useBalances } from './BalancesProvider';
import { getCustodyCurrencies } from '../../redux/selectors/currenciesSelectors';
const useStyles = makeStyles(() => createStyles({
    root: {
        position: 'fixed',
        width: '100%',
        maxWidth: '960px',
        minWidth: '760px',
        top: '60px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        '&.pinned': {
            left: 0,
            right: 0,
            transform: 'none',
            maxWidth: 'none',
            height: '60px',
        },
    },
}));
export const cTypes = {
    base: '',
    erc20: 'ERC20',
    bep20: 'BEP20',
};
const BalancesWidget = (props) => {
    const { onClose, open, masterTenant, customerCode, selectedClient, selectedCustomer, userClientId, currencies, balancesWidget, } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const [pinned, setPinned] = useState(balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned);
    const classes = useStyles();
    const { data, loading, activeTab, setActiveTab, fetchWithInterval } = useBalances();
    useEffect(() => {
        setPinned(balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned);
    }, [balancesWidget]);
    const getCurrencies = () => {
        dispatch({ type: FETCH_CURRENCIES_INIT });
    };
    const getBalances = () => {
        if (masterTenant) {
            if ((selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) && activeTab === 1) {
                fetchWithInterval('customers', selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code);
            }
            else if (activeTab === 0) {
                if (selectedClient) {
                    fetchWithInterval('clients', selectedClient);
                }
                else {
                    if (userClientId) {
                        fetchWithInterval('clients', userClientId);
                    }
                }
            }
        }
        else if (!customerCode) {
            if (activeTab === 0) {
                if (userClientId) {
                    fetchWithInterval('clients', userClientId);
                }
            }
            else {
                if (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) {
                    fetchWithInterval('customers', selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code);
                }
            }
        }
        else {
            if (customerCode) {
                fetchWithInterval('customers', customerCode);
            }
        }
    };
    useEffect(() => {
        getCurrencies();
    }, []);
    useEffect(() => {
        setActiveTab(!selectedCustomer ? 0 : 1);
        if (selectedCustomer && activeTab !== 0) {
            getBalances();
        }
    }, [selectedCustomer]);
    useEffect(() => {
        getBalances();
    }, [selectedClient, activeTab]);
    const handlePin = () => {
        setPinned(!pinned);
        dispatch({ type: BALANCES_STATE_UPDATE, pinned: !pinned });
        writeToLocalStorage('balancesWidget', { pinned: !pinned });
    };
    const handleTabChange = (event, tab) => {
        setActiveTab(tab);
    };
    if (!open) {
        return null;
    }
    return (React.createElement(Box, { className: `${classes.root} ${pinned ? 'pinned' : ''}` }, pinned ? (React.createElement(Paper, { component: Box, p: 0, sx: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        } },
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(Tooltip, { title: 'Expand' },
                React.createElement(IconButton, { onClick: handlePin },
                    React.createElement(ExpandIcon, null))),
            customerCode ? null : (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: 'Show client balances' },
                    React.createElement("span", null,
                        React.createElement(IconButton, { color: activeTab === 0 ? 'primary' : 'inherit', onClick: () => setActiveTab(0), disabled: !selectedClient },
                            React.createElement(PersonIcon, null)))),
                React.createElement(Tooltip, { title: 'Show customer balances' },
                    React.createElement("span", null,
                        React.createElement(IconButton, { color: activeTab === 1 ? 'primary' : 'inherit', disabled: !selectedCustomer, onClick: () => setActiveTab(1) },
                            React.createElement(PeopleIcon, null))))))),
        React.createElement(BalancesList, { pinned: balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned, currencies: currencies, balances: data, loading: loading }))) : (React.createElement(Box, { p: pinned ? 0 : 2, sx: {
            width: '100%',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: pinned ? 'row' : 'column',
            alignItems: pinned ? 'center' : '',
        } },
        React.createElement(Box, { mb: 2, sx: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            } },
            React.createElement(Typography, { variant: 'h6' }, "Balances"),
            customerCode ? null : (React.createElement(Box, null,
                React.createElement(Tabs, { value: activeTab, onChange: handleTabChange },
                    React.createElement(Tab, { label: 'Client' }),
                    React.createElement(Tab, { label: 'Customer', disabled: !selectedCustomer })))),
            React.createElement(Box, null,
                React.createElement(Tooltip, { title: 'Minimize view' },
                    React.createElement(IconButton, { onClick: handlePin },
                        React.createElement(UnfoldLessIcon, null))),
                React.createElement(IconButton, { onClick: onClose },
                    React.createElement(CloseOutlinedIcon, null)))),
        React.createElement(BalancesList, { pinned: balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned, currencies: currencies, balances: data })))));
};
const mapStateToProps = (state) => {
    var _a;
    const { masterTenant } = state.client;
    const userClientId = (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code;
    const customerCode = state.client.customer_code;
    const { selectedClient } = state.clientSearch;
    const { selectedCustomer } = state.customersSearch;
    const currencies = getCustodyCurrencies(state);
    const { balances } = state.workspace;
    return {
        masterTenant,
        customerCode,
        selectedClient,
        selectedCustomer,
        userClientId,
        currencies,
        balancesWidget: balances,
    };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BalancesWidget);
