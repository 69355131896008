import * as types from '../actionTypes/placeOrderActionTypes';
export const initialState = {
    markets: [],
    exchanges: [],
    limitPrice: '',
    totalPrice: '',
    amount: '',
    referenceId: '',
    selectedPair: null,
    selectedBase: null,
    selectedQuote: null,
    selectedExchange: null,
    orderType: null,
    partialOrder: false,
    loadingMarkets: false,
    loadingExchanges: false,
    newOrderLoading: false,
    newPartialLoading: false,
    closePartialLoading: false,
    createdSplitOrder: null,
    displaySuborderModal: false,
    exchangeNotAvailable: false,
    newOrderError: null,
    currencies: [],
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-unsafe-assignment, no-case-declarations,
  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
const placeOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_MARKETS_REQUEST:
            return Object.assign(Object.assign({}, state), { loadingMarkets: true });
        case types.FETCH_EXCHANGES_REQUEST:
            return Object.assign(Object.assign({}, state), { loadingExchanges: true });
        case types.FETCH_EXCHANGES_FAIL:
            return Object.assign(Object.assign({}, state), { loadingExchanges: false });
        case types.NEW_ORDER_CREATE_REQUEST:
            return Object.assign(Object.assign({}, state), { newOrderLoading: true, newOrderError: null });
        case types.NEW_ORDER_CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { newOrderLoading: false });
        case types.NEW_ORDER_CREATE_FAIL:
            return Object.assign(Object.assign({}, state), { newOrderLoading: false, newOrderError: action.message });
        case types.NEW_PARTIAL_ORDER_CREATE_REQUEST:
            return Object.assign(Object.assign({}, state), { newPartialLoading: true, createdSplitOrder: null });
        case types.NEW_PARTIAL_ORDER_CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { createdSplitOrder: action.splitOrder, newPartialLoading: false, displaySuborderModal: true });
        case types.PARTIAL_ORDER_CLOSE_REQUEST:
            return Object.assign(Object.assign({}, state), { closePartialLoading: true });
        case types.PARTIAL_ORDER_CLOSE_SUCCESS:
            return Object.assign(Object.assign({}, state), { closePartialLoading: false, displaySuborderModal: false, partialOrderCode: action.code });
        case types.NEW_PARTIAL_ORDER_CREATE_FAIL:
        case types.PARTIAL_ORDER_CLOSE_FAIL:
            return Object.assign(Object.assign({}, state), { newPartialLoading: false, closePartialLoading: false });
        case types.FETCH_MARKETS_SUCCESS:
            return Object.assign({}, state, {
                markets: [...action.markets],
                loadingMarkets: false,
                selectedExchange: {
                    value: action.markets[0].exchange_code,
                    label: action.markets[0].exchange_code.toLocaleUpperCase(),
                },
            });
        case types.FETCH_EXCHANGES_SUCCESS:
            const enabledExchanges = action.exchanges.filter(e => e.enabled);
            const selected = enabledExchanges && enabledExchanges.length > 0
                ? { value: enabledExchanges[0].code, label: enabledExchanges[0].name }
                : null;
            return Object.assign({}, state, {
                exchanges: [...action.exchanges],
                loadingExchanges: false,
                selectedExchange: selected,
            });
        case types.NEW_ORDER_BASE_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { selectedBase: action.base, selectedPair: Object.assign(Object.assign({}, state.selectedPair), { base: action.base.value }) });
        case types.NEW_ORDER_QUOTE_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { selectedQuote: action.quote, selectedPair: Object.assign(Object.assign({}, state.selectedPair), { quote: action.quote.value }) });
        case types.NEW_ORDER_EXCHANGE_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { selectedExchange: action.exchange, exchangeNotAvailable: false });
        case types.NEW_ORDER_EXCHANGE_UNAVAILABLE:
            return Object.assign(Object.assign({}, state), { exchangeNotAvailable: true });
        case types.NEW_ORDER_TYPE_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { orderType: action.orderType });
        case types.NEW_ORDER_AMOUNT_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { amount: action.amount });
        case types.NEW_ORDER_REFERENCE_ID_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { referenceId: action.referenceId });
        case types.NEW_ORDER_TOTAL_PRICE_UPDATE:
            return Object.assign(Object.assign({}, state), { totalPrice: action.totalPrice });
        case types.NEW_ORDER_LIMIT_PRICE_UPDATE:
            return Object.assign(Object.assign({}, state), { limitPrice: action.limitPrice });
        case types.SUBORDER_MODAL_TOGGLE:
            return Object.assign(Object.assign({}, state), { displaySuborderModal: !state.displaySuborderModal });
        case types.SET_UPDATE_PARTIAL_TYPE:
            return Object.assign(Object.assign({}, state), { partialOrder: action.value });
        case types.TRADING_CURRENCIES_UPDATE:
            return Object.assign(Object.assign({}, state), { currencies: action.currencies });
        default:
            return state;
    }
};
export default placeOrderReducer;
