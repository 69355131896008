import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../../../Dashboards/Dashboard';
import Currencies from '../../../Dashboards/Admin/components/Currencies/main';
import { CurrenciesProvider } from '../../../Dashboards/Admin/components/Currencies/CurrenciesProvider';
import PriceFeeds from '../../../Dashboards/Admin/components/Currencies/PriceFeeds/main';
import CurrencyNetworks from '../../../Dashboards/Admin/components/Currencies/Networks/main';
import LiquidityProviders from '../../../Dashboards/MasterTenant/components/LiquidityProviders/main';
import TradingCurrencies from '../../../Dashboards/Admin/components/Currencies/TradingCurrencies/TradingCurrencies';
export default function AdminRoot(props) {
    return (React.createElement(CurrenciesProvider, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/admin' },
                React.createElement(Redirect, { exact: true, to: 'admin/compliance' })),
            React.createElement(Route, { exact: true, path: '/admin/compliance' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { exact: true, path: '/admin/compliance-trading' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { exact: true, path: '/admin/exchanges' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { exact: true, path: '/admin/risk-manager' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { exact: true, path: '/admin/autohedger-order' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { exact: true, path: '/admin/currency-config' },
                React.createElement(Currencies, null)),
            React.createElement(Route, { exact: true, path: '/admin/trading-currencies' },
                React.createElement(TradingCurrencies, null)),
            React.createElement(Route, { exact: true, path: '/admin/price-feed' },
                React.createElement(PriceFeeds, null)),
            React.createElement(Route, { exact: true, path: '/admin/currency-networks' },
                React.createElement(CurrencyNetworks, null)),
            React.createElement(Route, { exact: true, path: '/admin/defi-strategies' },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { path: '/admin/liquidity-providers' },
                React.createElement(LiquidityProviders, null)),
            React.createElement(Route, { path: '*' },
                React.createElement(Redirect, { to: '/404' })))));
}
