var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IconButton, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Typography, Box, Tooltip, useTheme, } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { connect } from 'react-redux';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { getComparator, stableSort } from '@shared/helpers/tables';
import shortid from 'shortid';
import { Amount } from '@shared/components/styled/amount';
import { NumericFormat } from 'react-number-format';
const NumberCell = (props) => {
    const theme = useTheme();
    return (React.createElement(TableCell, { sx: { borderRight: `1px solid ${theme.palette.divider}` } },
        React.createElement(Amount, { textColor: props.color },
            React.createElement(NumericFormat, { thousandSeparator: "'", value: props.amount, displayType: 'text' }))));
};
const AutohedgerPnL = ({ columns }) => {
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [itemsToRender, setItemsToRender] = useState({});
    const [pnlItems, setPnlItems] = useState({});
    const [streamsValues, setStreamValues] = useState({});
    const [dymamicTotals, setDynamicTotals] = useState();
    const [staticTotals, setStaticTotals] = useState();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('trading_pair');
    const visibleRows = React.useMemo(() => stableSort(Object.values(itemsToRender), getComparator(order, orderBy)), [order, orderBy, itemsToRender]);
    const theme = useTheme();
    useEffect(() => {
        const { OrderBookWS } = window;
        const availableChannels = OrderBookWS.getAvailableChannels();
        const refs = [];
        for (const entry in pnlItems) {
            const refId = shortid.generate();
            const pair = pnlItems[entry].trading_pair.toLowerCase();
            const patterns = [
                `\\borderbook-simple-consolidated-${pair}\\b`,
                `\\borderbook-kraken-${pair}\\b`,
                `\\brfq-orderbook\\b.*\\b${pair}\\b`
            ];
            let channel = undefined;
            for (let i = 0; patterns.length > i; i++) {
                const regex = new RegExp(patterns[i]);
                channel = availableChannels.find((a) => {
                    const m = a.match(regex);
                    return !!m;
                });
                if (channel) {
                    break;
                }
            }
            if (channel) {
                OrderBookWS.addSubscription(channel, {
                    callback: (stream) => handlePriceUpdate(stream, entry),
                    componentId: refId,
                });
                refs.push({ channel, ref: refId });
            }
        }
        return () => {
            refs.forEach((r) => {
                OrderBookWS.removeSubscription(r.channel, r.ref);
            });
        };
    }, [pnlItems]);
    const handlePriceUpdate = (stream, asset) => {
        const ask = stream.payload.lift_ask ? +stream.payload.lift_ask : +stream.payload.asks[0].price;
        const bid = stream.payload.hit_bid ? +stream.payload.hit_bid : +stream.payload.bids[0].price;
        const currentPrice = ask != 0 && bid != 0 ? (ask + bid) / 2 : ask + bid;
        pnlItems[asset].realtime_price = currentPrice.toFixed(5);
        pnlItems[asset].total_unrealized = (+pnlItems[asset].open_exposure * currentPrice -
            +pnlItems[asset].average_exposure_value).toFixed(2);
        pnlItems[asset].position_value = (+pnlItems[asset].open_exposure * currentPrice).toFixed(2);
        setItemsToRender(Object.assign({}, pnlItems));
        onStreamUpdate({
            pair: asset,
            unrealizedValue: pnlItems[asset].total_unrealized,
            positionValue: pnlItems[asset].position_value,
        });
    };
    useEffect(() => {
        const totalsArr = Object.values(streamsValues) || [];
        let unrealized = 0;
        let positionValue = 0;
        if (totalsArr.length) {
            totalsArr &&
                totalsArr.forEach((item) => {
                    unrealized += item.unrealizedValue;
                    positionValue += item.positionValue;
                });
            setDynamicTotals({
                positionValue: positionValue.toFixed(2),
                unrealizedValue: unrealized.toFixed(2),
            });
        }
    }, [streamsValues]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter((c) => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        fetchData();
    }, []);
    const handleTableSort = (column) => {
        setOrderBy(column);
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        const apipath = `${String(getEnvSettings().adminApiUrl)}/risk-management/pnl`;
        yield axios.get(apipath).then((res) => {
            let average = 0;
            let realized = 0;
            let dailyRealized = 0;
            let weeklyRealized = 0;
            let monthlyRealized = 0;
            res.data.rows.forEach((item) => {
                average += Number(item.average_exposure_value);
                realized += Number(item.realized_pnl);
                dailyRealized += Number(item.last_day_realized_pnl);
                weeklyRealized += Number(item.last_week_realized_pnl);
                monthlyRealized += Number(item.last_month_realized_pnl);
            });
            setStaticTotals({
                averageValue: average.toFixed(2),
                realizedValue: realized.toFixed(2),
                dailyRealized: dailyRealized.toFixed(2),
                weeklyRealized: weeklyRealized.toFixed(2),
                monthlyRelalized: monthlyRealized.toFixed(2),
            });
            const dict = res.data.rows.reduce((acc, curr) => {
                acc[curr.base_currency] = curr;
                return acc;
            }, {});
            setPnlItems(dict);
        });
    });
    const onStreamUpdate = (data) => {
        const newTotals = streamsValues;
        newTotals[data.pair] = { positionValue: Number(data.positionValue), unrealizedValue: Number(data.unrealizedValue) };
        setStreamValues(Object.assign({}, newTotals));
    };
    const getColor = (amount) => {
        return Number(amount) == 0 || !amount
            ? theme.palette.text.primary
            : Number(amount) >= 0
                ? theme.palette.success.main
                : theme.palette.error.main;
    };
    return (React.createElement(Paper, { sx: { maxHeight: '100%', height: '100%' } },
        React.createElement(TableContainer, { style: { height: '100%', maxHeight: '100%', overflow: 'auto' } },
            React.createElement(Table, { stickyHeader: true, "aria-label": 'sticky table' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, columnsToRender.map((column) => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: 'left' },
                        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                            React.createElement(TableSortLabel, { onClick: () => handleTableSort(column.key) }, column.title),
                            column.description && (React.createElement(Tooltip, { title: column.description },
                                React.createElement(IconButton, { size: 'small' },
                                    React.createElement(InfoIcon, null)))))))))),
                React.createElement(TableBody, null,
                    visibleRows.length === 0 ? (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                            React.createElement(Typography, { variant: 'subtitle1' }, "No data available")))) : (visibleRows.map((a) => (React.createElement(TableRow, { key: a.base_currency },
                        React.createElement(TableCell, null, a.base_currency.toUpperCase()),
                        React.createElement(NumberCell, { amount: a.realtime_price }),
                        React.createElement(NumberCell, { amount: Number(a.open_exposure).toFixed(2) }),
                        React.createElement(NumberCell, { amount: a.position_value }),
                        React.createElement(NumberCell, { amount: Number(a.average_price).toString() }),
                        React.createElement(NumberCell, { amount: Number(a.average_exposure_value).toFixed(2) }),
                        React.createElement(NumberCell, { amount: Number(a.last_day_realized_pnl).toFixed(2), color: getColor(a.last_day_realized_pnl) }),
                        React.createElement(NumberCell, { amount: Number(a.last_week_realized_pnl).toFixed(2), color: getColor(a.last_week_realized_pnl) }),
                        React.createElement(NumberCell, { amount: Number(a.last_month_realized_pnl).toFixed(2), color: getColor(a.last_month_realized_pnl) }),
                        React.createElement(NumberCell, { amount: Number(a.realized_pnl).toFixed(2), color: getColor(a.realized_pnl) }),
                        React.createElement(NumberCell, { amount: a.total_unrealized, color: getColor(a.total_unrealized) }))))),
                    visibleRows.length > 0 ? (React.createElement(TableRow, { sx: {
                            position: 'sticky',
                            bottom: '0',
                            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey.A200 : theme.palette.grey[900],
                        } },
                        React.createElement(TableCell, { colSpan: 3 },
                            React.createElement(Typography, null,
                                React.createElement("b", null, "Totals:"))),
                        React.createElement(NumberCell, { color: getColor(dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.positionValue), amount: (dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.positionValue) || '' }),
                        React.createElement(TableCell, null),
                        React.createElement(TableCell, null),
                        React.createElement(NumberCell, { color: getColor(staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.dailyRealized), amount: (staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.dailyRealized) || '' }),
                        React.createElement(NumberCell, { color: getColor(staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.weeklyRealized), amount: (staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.weeklyRealized) || '' }),
                        React.createElement(NumberCell, { color: getColor(staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.monthlyRelalized), amount: (staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.monthlyRelalized) || '' }),
                        React.createElement(NumberCell, { color: getColor(staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.realizedValue), amount: (staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.realizedValue) || '' }),
                        React.createElement(NumberCell, { color: getColor(dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.unrealizedValue), amount: (dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.unrealizedValue) || '' }))) : null)))));
};
const mapStateToProps = (state, ownProps) => ({
    columns: getWidgetColumns(state, ownProps.widgetKey),
    widgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(AutohedgerPnL);
