/* eslint-disable camelcase, import/prefer-default-export, @typescript-eslint/no-unsafe-return,
  @typescript-eslint/no-unsafe-call, no-nested-ternary, no-console, @typescript-eslint/restrict-template-expressions */
import { createSelector } from 'reselect';
import Decimal from 'decimal.js';
const parseAmlResponse = (amlToParse) => {
    let res = null;
    try {
        res = JSON.parse(amlToParse.response);
    }
    catch (e) {
        console.error(e);
        // throw notice here
    }
    return res;
};
const adminOrders = (state) => state.admin.orders;
const adminWallets = (state) => state.admin.wallets;
const getDeposits = (state) => state.admin.deposits;
const getWithdrawals = (state) => state.admin.withdrawals;
const lineChartData = (state) => state.admin.lineChartData;
const adminCustomers = (state) => state.customersSearch.customers;
const adminAddressess = (state) => state.admin.withdrawalAddresses;
const adminComplianceSub = (state) => state.admin.complianceSubOrders;
const adminComplianceRegular = (state) => state.admin.complianceRegularOrders;
const adminApprovalsWithdrawals = (state) => state.admin.approvalsWithdrawals;
const clients = (state) => state.clientSearch.allClients;
export const getAdminWithdrawals = createSelector(adminApprovalsWithdrawals, approvalsWithdrawals => approvalsWithdrawals && approvalsWithdrawals.length ? approvalsWithdrawals : []);
export const getAdminWithdrawalAddresses = createSelector(adminAddressess, addresses => addresses && addresses.length ? addresses : []);
export const getAddressesToRender = createSelector(adminAddressess, (addresses) => {
    if (addresses.length === 0) {
        return [];
    }
    return addresses.map(a => Object.assign({}, a, { wallet_label: '' }));
});
export const getWithdrawalsToRender = createSelector(adminApprovalsWithdrawals, (approvalsWithdrawals) => {
    if (!approvalsWithdrawals || approvalsWithdrawals.length === 0) {
        return [];
    }
    return approvalsWithdrawals.map((r) => {
        const destination = !r.destination_type ? r.address
            : r.destination_type === 'address' ? r.destination_address : r.destination_wallet;
        const address = destination && Object.prototype.hasOwnProperty.call(destination, 'address')
            ? destination.address : '';
        const convert = {
            fee: r.fee,
            usd_fee: '',
            code: r.code,
            state: r.state,
            wallet_label: '',
            customer_code: '',
            client_code: r.client_uuid,
            tx_hash: r.tx_hash,
            created: r.created_at,
            updated: r.updated_at,
            address: address || '',
            wallet_code: r.wallet_code,
            reference_id: r.reference_id,
            currency_code: r.currency_code,
            failure_reason: r.failure_reason,
            source_address: r.source_address,
            requested_amount: r.requested_amount,
            failure_raw_error: r.failure_raw_error,
            fee_currency_code: r.fee_currency_code,
            destination_type: r.destination_type || '',
            requested_amount_usd: r.requested_amount_usd,
            address_label: destination ? destination.label : '',
            replaced_withdrawal_code: r.replaced_withdrawal_code || '',
            kyt_sub_status: r.kyt_sub_status,
            travel_rule_sub_status: r.travel_rule_sub_status,
        };
        return convert;
    });
});
export const getOrdersToRender = createSelector(adminOrders, clients, (orders, clients) => {
    if (orders.length === 0) {
        return [];
    }
    return orders.map((i) => {
        var _a;
        const clientName = (_a = clients.find(c => c.code === i.client_code)) === null || _a === void 0 ? void 0 : _a.company_name;
        const convert = {
            code: i.code,
            client: clientName || i.client_code,
            amount: i.amount,
            status: i.status,
            created: i.created,
            base: i.market_item.base,
            quote: i.market_item.quote,
            executedPrice: i.executed.price,
            executedValue: i.executed.value,
            action_type: `${i.type} ${i.action}`,
            exchange: i.market_item.exchange_code,
            pair: `${i.market_item.base} > ${i.market_item.quote}`,
        };
        return convert;
    });
});
export const getTransactions = createSelector(getDeposits, getWithdrawals, (deposits, withdrawals) => {
    if (!deposits || deposits.length === 0 || !withdrawals || (withdrawals === null || withdrawals === void 0 ? void 0 : withdrawals.length) === 0) {
        return [];
    }
    const dTransactions = deposits.map((d) => {
        let riskScore = new Decimal(0);
        if (d.aml_checks) {
            for (const amlCheck of d.aml_checks) {
                const r = parseAmlResponse(amlCheck);
                if (r && r.risk_score) {
                    riskScore = riskScore.add(r.risk_score);
                }
                else if (r && r.address && typeof r.address !== 'string') {
                    riskScore = riskScore.add(r.address.risk_score);
                }
            }
        }
        return {
            aml_checks: d.aml_checks || [],
            code: d.code,
            state: d.state,
            type: 'deposit',
            currency_code: d.currency_code,
            tx_hash: d.compliance_tx_hash,
            amount: d.compliance_amount,
            amount_usd: '',
            created_at: d.compliance_received_at,
            fee: d.compliance_to_custody_fee,
            note: d.code,
            risk_score: riskScore.toFixed(),
        };
    });
    const wTransactions = withdrawals.map(w => ({
        aml_checks: null,
        code: w.code,
        state: w.state,
        type: 'withdrawal',
        currency_code: w.currency_code,
        tx_hash: w.tx_hash,
        amount: w.received_amount,
        amount_usd: '',
        created_at: w.created_at,
        fee: w.fee,
        note: w.code,
        risk_score: null,
    }));
    return [...dTransactions, ...wTransactions];
});
export const getWalletsToRender = createSelector(adminWallets, adminCustomers, (wallets, customers) => {
    if (wallets.length === 0) {
        return [];
    }
    return wallets.map((r) => {
        var _a, _b, _c;
        const customer = r.customer_code ? customers.find(c => c.code === r.customer_code) : null;
        const convert = {
            code: r.code,
            type: r.type,
            label: r.label,
            state: r.state,
            balance_total: (_a = r.balance) === null || _a === void 0 ? void 0 : _a.total,
            balance_locked: (_b = r.balance) === null || _b === void 0 ? void 0 : _b.locked,
            balance_available: (_c = r.balance) === null || _c === void 0 ? void 0 : _c.available,
            usd_total: '',
            usd_available: '',
            usd_locked: '',
            external_id: r.external_id,
            currency_code: r.currency_code,
            currency_ticker: r.currency_ticker,
            accounting_type: r.accounting_type,
            created_at: r.created_at,
            customer_code: customer ? customer.company_name : r.customer_code ? r.customer_code : 'n/a',
            client_code: r.client_code,
            is_migrated: r.is_migrated,
            is_new_provider: r.is_new_provider,
            deposit_address: r.compliance_addresses.length
                ? r.compliance_addresses[0].state === 'active'
                    ? r.compliance_addresses[0].address
                    : 'not active'
                : 'n/a',
            note: r.code,
        };
        return convert;
    });
});
export const getComplianceOrders = createSelector(adminComplianceSub, adminComplianceRegular, (sub, regular) => [...sub, ...regular]);
export const getLineChartData = createSelector(lineChartData, (chartData) => {
    if (chartData === 'n/a') {
        return 'n/a';
    }
    return chartData;
});
