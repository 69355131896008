import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Box, Typography, useTheme, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { connect } from 'react-redux';
import { getTradingBases } from '../../../../../redux/selectors/selectors';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../styling/style';
import { getCustodyWallets } from '../../../../../redux/selectors/custodySelectors';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
import { getCustodyCurrencies, getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
import CustodyWalletService from '../../../../../services/custodyWalletService';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const typeOptyons = [
    { label: 'Custody Wallet', value: 'regular' },
    { label: 'Trading Wallet', value: 'trading' },
];
const getWalletsOptions = (clientFilter, typeFilter, customerCode, currencyFilter) => {
    if (!currencyFilter)
        return Promise.resolve([]);
    const svc = new CustodyWalletService({
        url: `/custody/${clientFilter}/wallets?limit=1000&state=active${customerCode ? `&customer_code=${customerCode}` : ''}${currencyFilter ? `&currency_code=${currencyFilter}` : ''}${typeFilter ? `&accounting_type=${typeFilter}` : ''}`,
        method: 'get',
    });
    return svc.makeRequest().then(wallets => {
        return wallets.records.map((w) => {
            const address = w.compliance_addresses.find((a) => a.state === 'active');
            const addressData = address ? address.address : '';
            return { label: `${w.label} ${addressData}`, value: w.code };
        });
    }).catch(() => []);
};
const WalletModal = ({ open, loading, customers, toggleFunc, allWallets, currencies, optionsBase, customerCode, clientCode, allCurrencies, handleAddWallet, dispatchLoadMarkets, dispatchLoadExchanges, }) => {
    var _a;
    const [label, setLabel] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [requireFeeWallet, setRequireFeeWallet] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [parentWallets, setParentWallets] = useState([]);
    const [type, setType] = useState(typeOptyons[0]);
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [currency, setCurrency] = useState(allCurrencies.length ? allCurrencies[0] : null);
    const helperTextStyles = useHelperTextStyles();
    const theme = useTheme();
    const addNewWallet = () => {
        const payload = {
            label,
            accounting_type: (type === null || type === void 0 ? void 0 : type.value) || '',
            currency_code: (currency === null || currency === void 0 ? void 0 : currency.value) || '',
            customer_code: customerCode || (customer === null || customer === void 0 ? void 0 : customer.value) || '',
            fee_source_wallet_code: (selectedWallet === null || selectedWallet === void 0 ? void 0 : selectedWallet.value) || '',
        };
        handleAddWallet(payload);
        setLabel('');
        setType(null);
        setSelectedWallet(null);
        setCurrency(allCurrencies.length ? allCurrencies[0] : null);
    };
    const onFieldChange = (evt) => {
        const { value, name } = evt.target;
        if (name === 'label') {
            setLabel(value);
        }
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrency(newValue);
    };
    const handleCustomerChange = (event, newValue) => {
        setCustomer(newValue);
    };
    const handleSelectWallet = (event, newValue) => {
        setSelectedWallet(newValue);
    };
    const handleSelectType = (event, newValue) => {
        setType(newValue);
    };
    const getParentWallet = (currency) => {
        return currencies.find((c) => c.code.toUpperCase() === (currency === null || currency === void 0 ? void 0 : currency.toUpperCase()));
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if ((currency && (currency === null || currency === void 0 ? void 0 : currency.value) != '' && customer) || customerCode) {
            const currencyType = currencies.find((c) => c.code.toUpperCase() === (currency === null || currency === void 0 ? void 0 : currency.value.toUpperCase()));
            getWalletsOptions(clientCode, (type === null || type === void 0 ? void 0 : type.value) || '', (customer === null || customer === void 0 ? void 0 : customer.value) || customerCode, (currencyType === null || currencyType === void 0 ? void 0 : currencyType.parent_code) || '')
                .then(wallets => {
                setParentWallets(wallets);
            });
        }
    }, [currency, allWallets, type, customer]);
    useEffect(() => {
        if ((!selectedWallet && requireFeeWallet && !selectedWallet) ||
            !label ||
            !currency ||
            (!customer && !customerCode)) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [currency, selectedWallet, label, customerCode, type, customer]);
    useEffect(() => {
        if (currency) {
            const currentCurrency = currencies.find((c) => currency.value === c.code.toUpperCase());
            setRequireFeeWallet(!!(currentCurrency === null || currentCurrency === void 0 ? void 0 : currentCurrency.parent_code));
            setSelectedWallet(null);
        }
    }, [currency]);
    useEffect(() => {
        if ((type === null || type === void 0 ? void 0 : type.value) === 'trading') {
            const filtered = allCurrencies.filter((c) => optionsBase.find((b) => b.value === c.value));
            if (filtered.length) {
                setCurrenciesOptions([{ label: 'Select currency', value: '' }, ...filtered]);
            }
            else {
                setCurrenciesOptions([]);
            }
        }
    }, [type, allCurrencies, optionsBase]);
    useEffect(() => {
        if ((type === null || type === void 0 ? void 0 : type.value) === 'trading') {
            setCurrency(currenciesOptions.length ? currenciesOptions[0] : null);
        }
    }, [type, currenciesOptions]);
    if (loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: '33px' }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: 'sm', fullWidth: true },
        React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Create new Wallet"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 3, pt: 1 },
                customerCode ? null : (React.createElement(Autocomplete, { id: 'customer', size: 'small', value: customer, options: customers, disabled: !!customerCode, placeholder: 'Select customer', onChange: handleCustomerChange, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: 'li' }, props, { key: option.value }), option.label)), getOptionLabel: (option) => option.label, renderInput: (params) => (React.createElement(TextField, Object.assign({ FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${(customer === null || customer === void 0 ? void 0 : customer.value) === '' || !customer ? 'Customer is required' : ''}`, required: true }, params, { label: 'Customer', variant: 'outlined' }))) })),
                React.createElement(Autocomplete, { id: 'type', size: 'small', value: type, options: typeOptyons, placeholder: 'Select type', onChange: handleSelectType, getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Type', variant: 'outlined' })) }),
                React.createElement(Autocomplete, { id: 'currency', size: 'small', value: currency, placeholder: 'Select currency', onChange: handleCurrencyChange, getOptionLabel: (option) => option.label, options: (type === null || type === void 0 ? void 0 : type.value) === 'trading' ? currenciesOptions : allCurrencies, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: 'Currency', variant: 'outlined', required: true, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${(currency === null || currency === void 0 ? void 0 : currency.value) === '' || !currency ? 'Field is required' : ''}` }))) }),
                parentWallets.length > 0 ? (React.createElement(Autocomplete, { id: 'wallet', size: 'small', value: selectedWallet, options: parentWallets, placeholder: 'Select wallet', onChange: handleSelectWallet, getOptionLabel: (option) => option.label, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: 'Wallet', variant: 'outlined', helperText: `${(selectedWallet === null || selectedWallet === void 0 ? void 0 : selectedWallet.value) === '' || !selectedWallet ? 'Wallet is required' : ''}` }))) })) : null,
                React.createElement(TextField, { fullWidth: true, size: 'small', name: 'label', value: label, label: 'Label', variant: 'outlined', onChange: onFieldChange, InputLabelProps: { shrink: true } }),
                requireFeeWallet && parentWallets.length === 0 ? (React.createElement(Box, { px: 2 },
                    React.createElement(Typography, { variant: 'subtitle2', color: theme.palette.warning.main },
                        "To create ",
                        React.createElement("b", null, currency === null || currency === void 0 ? void 0 : currency.label),
                        " wallet you need to provide corresponding fee wallet. You have no available fee wallets for the current customer. ",
                        React.createElement("br", null),
                        "Create ",
                        React.createElement("b", null, (_a = getParentWallet(currency === null || currency === void 0 ? void 0 : currency.value)) === null || _a === void 0 ? void 0 : _a.parent_code.toUpperCase()),
                        " wallet first"))) : null)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: 'primary', variant: 'outlined', disabled: disabled, onClick: addNewWallet }, "Create"),
            React.createElement(Button, { variant: 'outlined', onClick: () => toggleFunc() }, "Close"))));
};
const mapStateToProps = (state) => {
    var _a;
    return ({
        optionsBase: getTradingBases(state),
        allWallets: getCustodyWallets(state),
        currencies: getCustodyCurrencies(state),
        customerCode: state.client.customer_code,
        clientCode: (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code,
        allCurrencies: [{ label: 'Select currency', value: '' }, ...getCustodyCurrenciesOptions(state)],
        customers: getCustomersSelectedInputInterface(state),
    });
};
const dispatchToProps = (dispatch) => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, dispatchToProps)(WalletModal);
