import React, { useState, useMemo } from 'react';
import Decimal from 'decimal.js';
import { NumericFormat } from 'react-number-format';
import { Chip, TableRow, TableCell, Box, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SuborderModal from '../../Suborders/main';
import SubOrderbookModal from './SubOrderbookModal';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
import { DateCell } from '@shared/components/styled/tableCells';
import { format, parseISO } from 'date-fns';
const envSettings = getEnvSettings();
const getExchangeOption = (exchanges, code) => {
    const option = exchanges.find(exchange => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
/* eslint-disable camelcase, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
const SubOrderbookItem = ({ item, exchanges, theme }) => {
    const { executed, created, action, status, market_item, type, amount, partial_order_uuid, } = item;
    const [modalOpen, setModalOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleInfoModal = () => {
        setInfoModalOpen(!infoModalOpen);
    };
    const getOrderExecutionPrice = (order) => {
        if (order.type === 'Limit' && order.executed.price === '0') {
            return order.limit_price;
        }
        return order.executed.price;
    };
    const executedPrice = getOrderExecutionPrice(item);
    const getTotalFeeValue = (executedBrokerFee, executedBankFee) => Decimal
        .add(executedBrokerFee, executedBankFee);
    const getAmountWithFees = (executedValue, executedBrokerFee, executedBankFee, act) => {
        const executedDecimal = new Decimal(executedValue);
        if (act === 'Sell') {
            return executedDecimal.minus(getTotalFeeValue(executedBrokerFee, executedBankFee)).toFixed(2);
        }
        return executedDecimal.plus(getTotalFeeValue(executedBrokerFee, executedBankFee)).toFixed(2);
    };
    const exchangeName = useMemo(() => getExchangeOption(exchanges, market_item.exchange_code), [exchanges, market_item]);
    const total = getAmountWithFees(executed.value, executed.broker_fee, executed.bank_fee, action);
    const actionClass = action === 'Buy' ? 'marketItem_bid_colors' : 'marketItem_ask_colors';
    return React.createElement(React.Fragment, null,
        React.createElement(TableRow, { key: created, className: "partial_order_item_wrap" },
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement("div", { className: actionClass }, action)),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement("div", null, type)),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement("span", null,
                    React.createElement(NumericFormat, { displayType: "text", value: new Decimal(amount).valueOf(), thousandSeparator: envSettings.thousandDivider }),
                    "\u00A0",
                    market_item.base)),
            React.createElement(TableCell, { className: "order_list_pair_style", onClick: toggleModal },
                React.createElement(Box, { display: 'flex' },
                    React.createElement(Chip, { label: market_item.base, size: "small" }),
                    React.createElement(ChevronRightIcon, null),
                    React.createElement(Chip, { label: market_item.quote, size: "small" }))),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement("div", null, exchangeName)),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement(NumericFormat, { displayType: "text", value: new Decimal(executedPrice).toFixed(2), thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                market_item.quote),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement(NumericFormat, { value: getAmountWithFees(executed.value, executed.broker_fee, executed.bank_fee, action), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                market_item.quote),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement("div", { style: { whiteSpace: 'nowrap' } },
                    React.createElement(DateCell, null, format(parseISO(created), 'dd-MM-yyyy')),
                    ",\u00A0",
                    React.createElement(DateCell, null, format(parseISO(created), 'HH:mm:ss')))),
            React.createElement(TableCell, { onClick: toggleModal },
                React.createElement(Chip, { size: "small", label: status === 'CanceledPartiallyFilled' ? 'Canceled Partially Filled' : status, style: statusBadgeColor(statusBadgeData.orderStatusBackgroundsRegular[status], statusBadgeData.orderStatusBordersRegular[status], statusBadgeData.orderStatusColorsRegular[status]).badge })),
            React.createElement(TableCell, null,
                React.createElement(Box, { display: 'flex', gap: 1 },
                    React.createElement(Button, { variant: "outlined", onClick: toggleInfoModal, style: { padding: 0 } }, "details")))),
        modalOpen && React.createElement(SuborderModal, { open: modalOpen, toggleFunc: toggleModal, splitCode: partial_order_uuid }),
        React.createElement(SubOrderbookModal, { item: item, total: total, theme: theme, open: infoModalOpen, toggleFunc: toggleInfoModal }));
};
export default SubOrderbookItem;
