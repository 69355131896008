const Messages = {
    CLIENT_FETCH: 'Failed to load Clients data',
    USER_FETCH: 'Failed to load User data',
    CLIENTS_FETCH: 'Failed to load Clients',
    CLIENT_CREATE: 'Failed to create Client',
    CLIENT_UPDATE: 'Failed to update Client',
    CUSTOMER_CREATE: 'Failed to create Customer',
    CUSTOMER_UPDATE: 'Failed to update Customer',
    CUSTOMER_INVITE: 'Failed to invite Customer',
    CLIENT_BALANCES_FETCH: 'Failed to load Client balances',
    CUSTOMER_FETCH: 'Failed to load Customer data',
    CUSTOMER_BALANCES_FETCH: 'Failed to load Customer balances',
    EXCHANGE_BALANCES_FETCH: 'Failed to load Exchanges balances',
    EXCHANGE_BALANCES_UPDATE: 'Failed to update latest Exchanges balances',
    CUSTOMERS_FETCH: 'Failed to load customers list',
    CUSTOMER_BALANCES_UPDATE: 'Failed to update customer balances',
    LAYOUT_SCHEMA_FETCH: 'Layout fetch failed',
    LAYOUT_SCHEMA_SAVE: 'Could not save layout',
    ORDER_SIGN: 'Failed to sign order',
    STRATEGY_APPROVE: 'Failed to approve strategy',
    ORDER_ABORT: 'Failed to abort order',
    ORDER_CANCEL: 'Failed to cancel order',
    ORDERS_FETCH: 'Failed to fetch orders',
    ORDERS_SILENT_WS_UPDATE: 'Silent update failed for orders: partials, suborders',
    ORDERS_PARTIAL_FETCH: 'Failed to fetch partial orders',
    ORDER_PARTIAL_FETCH: 'Failed to fetch partial order, with id: ',
    ORDERS_SUBORDERS_FETCH: 'Failed to fetch orders: Sub orders',
    ORDERS_ALL_CANCEL: 'Failed to cancel ALL orders',
    MARKETS_FETCH: 'Failed to load markets',
    EXCHANGES_FETCH: 'Failed to load exchanges',
    EXCHANGES_ENABLE: 'Failed to enable exchange',
    EXCHANGES_DISABLE: 'Failed to disable exchange',
    POST_NEW_ORDER: 'Failed to submit order',
    POST_NEW_SUB_ORDER: 'Failed to submit sub order',
    POST_NEW_PARTIAL_ORDER: 'Failed to submit partial order',
    CLOSE_PARTIAL_ORDER: 'Failed to close partial order',
    REMOVE_SUB_ORDER: 'Failed to remove sub-order',
    EXECUTE_SUB_ORDER: 'Failed to execute sub-order',
    TOKEN_RESET: 'Failed to reset token',
    API_TOKEN_FETCH: 'Failed to load api tokens',
    API_TOKEN_ADD: 'Failed to add api tokens',
    API_TOKEN_DELETE: 'Failed to delete api tokens',
    SCHEDULERS_FETCH: 'Failed to load schedulers',
    PERMISSIONS_FETCH: 'Failed to load permissions',
    PERMISSIONS_UPDATE: 'Failed to update permissions',
    DEPOSIT_ADDRESSES_FETCH: 'Failed to load Deposit Addresses',
    CUSTODY_DEPOSIT_UPDATE: 'Failed to update Custody Deposit',
    CUSTODY_DEPOSIT_AML_OVERRIDE: 'Failed to initiate AML check override',
    CUSTODY_WALLETS_FETCH: 'Failed to load Wallets',
    CUSTODY_WALLETS_CREATE: 'Failed to create Wallets',
    CUSTODY_WALLETS_UPDATE: 'Failed to update Wallets',
    CLIENTS_WALLETS_UPDATE: 'Failed to update Wallets',
    CUSTODY_WALLETS_ACTIVATE: 'Failed to activate/deactivate Wallets',
    CUSTODY_WITHDRAWALS_FETCH: 'Failed to load Withdrawals',
    MANAGE_WITHDRAWALS_FETCH: 'Failed to load Withdrawals',
    CUSTODY_WITHDRAWALS_CREATE: 'Failed to create Withdrawals',
    WITHDRAWAL_ABORT: 'Failed to abort Withdrawal',
    WITHDRAWAL_ADDRESSES_FETCH: 'Failed to load Withdrawal Addresses',
    ADDRESSES_BULK_APPROVE: 'Failed to bulk approve Withdrawal Addresses',
    WITHDRAWALS_BULK_SIGN: 'Failed to sign withdrawals',
    WITHDRAWAL_ADDRESSES_SIGN: 'Failed to sign Withdrawal Addresses',
    WITHDRAWAL_ADDRESSES_AML: 'Failed to add Withdrawal Addresses to manual AML check list',
    WITHDRAWAL_ADDRESSES_MASTER_SIGN: 'Failed to master sign Withdrawal Addresses',
    WITHDRAWAL_ADDRESSES_APPROVE: 'Failed to approve Withdrawal Addresses',
    WITHDRAWAL_ADDRESSES_DELETE: 'Failed to delete Withdrawal Addresses',
    WITHDRAWAL_ADDRESSES_REMOVE: 'Failed to remove Withdrawal Addresses',
    WITHDRAWAL_ADDRESSES_BOOST: 'Failed to boost Withdrawal Addresses',
    SETTLEMENT_INFO_FETCH: 'Failed to load Settlement info',
    REGISTER_USER: 'Failed to register',
    REGISTER_INVITE_FETCH: 'Failed to load register invite',
    CLEARER_WITHDRAWAL_FETCH: 'Failed to load Clearer Withdrawals',
    CLEARER_WITHDRAWAL_CREATE: 'Failed to create Clearer Withdrawal',
    RATES_FETCH: 'Failed to load rates',
    USERS_FETCH: 'Failed to load Users',
    INVITES_FETCH: 'Failed to load Invites',
    WITHDRAWAL_SIGN: 'Failed to sign Withdrawal',
    USERS_ENABLE_DISABLE: 'Failed to enable/disable user',
    RESET_PASSWORD: 'Failed to reset password',
    UNBAN_USER: 'Failed to unban user',
    LOGIN_USER: 'Failed to login',
    LOGOUT_USER: 'Failed to logout',
    SEND_INVITE: 'Failed to send invite',
    RE_SEND_INVITE: 'Failed to re-send invite',
    CREATE_REPORT: 'Failed to create report',
    FETCH_NOTES: 'Failed to fetch notes ',
    CUSTODY_FEES_FETCH: 'Failed to load custody fees',
    TRANSACTION_NOTES_CREATE: 'Failed to add note',
    INSTRUMENTS_FETCH: 'Failed to load Instruments',
    RFQ_SETTINGS_FETCH: 'Failed to load RFQ Settings',
    RFQ_SETTINGS_CREATE: 'Failed to create RFQ Settings',
    RFQ_SETTINGS_UPDATE: 'Failed to update RFQ Settings',
    RFQ_SETTINGS_DELETE: 'Failed to delete RFQ Settings',
    RFQ_SETTINGS_ASSIGN: 'Failed to assign RFQ Setting',
    RFQ_SETTINGS_UNASSIGN: 'Failed to unassign RFQ Setting',
    AUTOHEDGER_FETCH: 'Failed to load Autohedgers',
    AUTOHEDGER_BALANCE_FETCH: 'Failed to load Autohedger balances',
    AUTOHEDGER_CREATE: 'Failed to create Autohedger',
    AUTOHEDGER_DELETE: 'Failed to delete Autohedger',
    SETTLEMENTS_INITIATE: 'Failed to initiate settlements',
    SETTLEMENT_LINE_CREATE: 'Failed to create settlement line',
    SETTLEMENT_LINE_FETCH: 'Failed to load settlement line',
    SETTLEMENTS_EXECUTE: 'Failed to execute settlement',
    SETTLEMENT_LINE_UPDATE: 'Failed to update settlement line',
    SETTLEMENTS_DECLINE: 'Failed to decline settlement',
    SETTLEMENTS_FETCH: 'Failed to fetch settlements',
    SETTLEMENTS_ADDRESS_FETCH: 'Failed to fetch settlement address',
    TRADING_WITHDRAWAL_APPROVE: 'Failed to approve Withdrawal',
    TRADING_WITHDRAWAL_DECLINE: 'Failed to decline Withdrawal',
    TRADING_WITHDRAWAL_SIGN: 'Failed to sign Withdrawal',
    TRADING_DEPOSIT_REVERT: 'Failed to revert Deposit',
    TRADING_TRANSFERS_FETCH: 'Failed to fetch trading transfers',
    TRADING_DEPOSIT_CREATE: 'Failed to create trading deposit',
    TRADING_WITHDRAWAL_CREATE: 'Failed to create trading withdrawal',
    TRADING_TRANSFERS_CREATE: 'Failed to create trading transfer',
    FETCH_FILES: 'Failed to fetch files ',
    CURRENCY_CONFIG_FETCH: 'Failed to load currencies',
    CURRENCY_CONFIG_UPDATE: 'Failed to update currency',
    CUSTODY_CURRENCY_FETCH: 'Failed to load currencies',
    CUSTODY_CURRENCY_UPDATE: 'Failed to update currency',
    CUSTODY_CURRENCY_ADD: 'Failed to add currency',
    WALLET_BALANCE_UPDATE: 'Failed to update Wallet balance',
    WEBHOOK_FETCH: 'Failed to load webhooks',
    WEBHOOK_ADD: 'Failed to add webhook',
    WEBHOOK_DELETE: 'Failed to delete webhook',
    PRICE_FEEDS_FETCH: 'Failed to load price feeds',
    PRICE_FEED_ADD: 'Failed to add price feed',
    PRICE_FEED_UPDATE: 'Failed to update price feed',
    NETWORKS_FETCH: 'Failed to fetch networks',
    NETWORK_ADD: 'Failed to add network',
    NETWORK_UPDATE: 'Failed to update network',
    RUNTIME_SETTINGS_FETCH: 'Failed to load runtime settings',
    RUNTIME_SETTINGS_UPDATE: 'Failed to update runtime settings',
};
export default Messages;
