import React from 'react';
import ClientSettlementsTable from './ClientSettlements';
import { Box, Container, Typography } from '@mui/material';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class ClientSettlements extends React.Component {
    render() {
        return (React.createElement(Box, { p: 1 },
            React.createElement(Container, { maxWidth: 'xl' },
                React.createElement(Typography, { variant: 'h6', p: 1 }, "Settlements Etoro")),
            React.createElement(ClientSettlementsTable, { type: "etoro" })));
    }
}
export default ClientSettlements;
