import React, { useEffect, useState } from 'react';
import axios from 'axios';
import saveAs from 'file-saver';
import { Box, MenuItem, TextField } from '@mui/material';
import DateFnsAdapter from '@date-io/date-fns';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
var REPORTS_UUIDS;
(function (REPORTS_UUIDS) {
    REPORTS_UUIDS["BALANCES"] = "0f83aef0-fd57-4bc0-bd4d-1720cd017c54";
    REPORTS_UUIDS["RECONCILIATION"] = "72577877-6539-4298-9876-48185d9f3000";
    REPORTS_UUIDS["BALANCES_FUTURES"] = "a347ea75-9b82-47ea-af0c-124cbd4a212f";
    REPORTS_UUIDS["EMIR_TRADES"] = "820a6352-0ae0-4386-9fe7-e9fa770019db";
    REPORTS_UUIDS["EMIR_POSITION"] = "46c1b566-1663-40be-a1c6-58a4370cd222";
    REPORTS_UUIDS["EMIR_CLIENT"] = "37203886-0689-489a-ba88-d1b872237426";
    REPORTS_UUIDS["EMIR_HAIRCUT"] = "da153988-fbc9-480b-a37f-1b4e2c36e6d6";
    REPORTS_UUIDS["EMIR_MARGIN"] = "c280ca60-1125-4265-8011-fe9ebfe6760e";
    REPORTS_UUIDS["EMIR_SYMBOL"] = "4d804412-a261-41cb-9dca-21d624ca5c56";
    REPORTS_UUIDS["ETORO_RECON"] = "c7d51a34-0b0d-4153-bf90-a2ce0edd1614";
    REPORTS_UUIDS["ETORO_SETTLEMENT"] = "782b331b-cf86-486b-9940-368ae21541ef";
    REPORTS_UUIDS["LP_SETTLEMENT"] = "d2c5e787-fc22-4484-84ff-9fa67819dfc8";
})(REPORTS_UUIDS || (REPORTS_UUIDS = {}));
var DATE_OPTIONS;
(function (DATE_OPTIONS) {
    DATE_OPTIONS[DATE_OPTIONS["SINGLE"] = 0] = "SINGLE";
    DATE_OPTIONS[DATE_OPTIONS["RANGE"] = 1] = "RANGE";
})(DATE_OPTIONS || (DATE_OPTIONS = {}));
const reportTypes = [
    { value: REPORTS_UUIDS.BALANCES, label: 'Balances', date: DATE_OPTIONS.SINGLE },
    { value: REPORTS_UUIDS.RECONCILIATION, label: 'Reconciliation', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.BALANCES_FUTURES, label: 'Balances Futures', date: DATE_OPTIONS.SINGLE },
    { value: REPORTS_UUIDS.EMIR_TRADES, label: 'EMIR Trades', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.EMIR_POSITION, label: 'EMIR Position', date: DATE_OPTIONS.SINGLE },
    { value: REPORTS_UUIDS.EMIR_CLIENT, label: 'EMIR Client', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.EMIR_HAIRCUT, label: 'EMIR Haircut', date: DATE_OPTIONS.SINGLE },
    { value: REPORTS_UUIDS.EMIR_MARGIN, label: 'EMIR Margin', date: DATE_OPTIONS.SINGLE },
    { value: REPORTS_UUIDS.EMIR_SYMBOL, label: 'EMIR Symbol', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.ETORO_RECON, label: 'Etoro Reconciliation', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.ETORO_SETTLEMENT, label: 'Etoro Settlement', date: DATE_OPTIONS.RANGE },
    { value: REPORTS_UUIDS.LP_SETTLEMENT, label: 'LP Settlement', date: DATE_OPTIONS.RANGE },
];
const defaultDateTill = new Date();
const defaultDateFrom = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
const ReportByID = () => {
    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTill, setDateTill] = useState(defaultDateTill);
    const [selectedReport, setSelectedReport] = useState(reportTypes[0]);
    const [loading, setLoading] = useState(false);
    const dateFns = new DateFnsAdapter();
    useEffect(() => {
        const m = dateFns.date(new Date());
        m.setMinutes(0);
        m.setSeconds(0);
        m.setHours(0);
    }, []);
    const handleDateFromChange = (date, context) => {
        setDateFrom(!context.validationError ? date : null);
    };
    const handleDateTillChange = (date, context) => {
        setDateTill(!context.validationError ? date : null);
    };
    const handleDownload = () => {
        let requestBody = {};
        if ((selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.date) === DATE_OPTIONS.RANGE && dateFrom && dateTill) {
            requestBody = {
                report_uuid: selectedReport.value,
                format: 'csv',
                trade_filter: {
                    date_from: new Date(dateFrom.setMilliseconds(0)).toISOString(),
                    date_to: new Date(dateTill.setMilliseconds(0)).toISOString(),
                },
            };
        }
        if ((selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.date) === DATE_OPTIONS.SINGLE && dateTill) {
            requestBody = {
                report_uuid: selectedReport.value,
                format: 'csv',
                date_at: new Date(dateTill.setMilliseconds(0)).toISOString(),
            };
        }
        if (!requestBody) {
            return;
        }
        setLoading(true);
        axios
            .post('/report/generate', requestBody)
            .then((response) => {
            const date = selectedReport.date === DATE_OPTIONS.SINGLE
                ? dateTill === null || dateTill === void 0 ? void 0 : dateTill.toLocaleDateString()
                : `${dateFrom === null || dateFrom === void 0 ? void 0 : dateFrom.toLocaleDateString()}-${dateTill === null || dateTill === void 0 ? void 0 : dateTill.toLocaleDateString()}`;
            const file = new File([response.data || ''], `${date}_${selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.label}_DLT_Finance.csv`, {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(file, `${date}_${selectedReport === null || selectedReport === void 0 ? void 0 : selectedReport.label}_DLT_Finance.csv`);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const handleTypeChange = (event) => {
        const report = reportTypes.find((r) => r.value === event.target.value);
        if (report) {
            setSelectedReport(report);
        }
    };
    return (React.createElement(Box, { pt: 3, sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
        React.createElement(Box, { sx: { '& .MuiTextField-root': { width: '25ch' } } },
            React.createElement(TextField, { label: 'Type', select: true, fullWidth: true, size: 'small', variant: 'outlined', value: selectedReport.value, name: 'selectSettlementType', onChange: handleTypeChange }, reportTypes.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))))),
        selectedReport.date === DATE_OPTIONS.SINGLE ? (React.createElement(Box, { display: 'flex', gap: 1, sx: { '& .MuiTextField-root': { width: '25ch' } } },
            React.createElement(DateTimePicker, { disableFuture: true, ampm: false, format: 'dd/MM/yyyy HH:mm:ss', label: 'Date', slotProps: {
                    actionBar: {
                        actions: ['clear', 'cancel', 'today'],
                    },
                    textField: {
                        size: 'small',
                    },
                }, views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'], value: dateTill, onChange: handleDateTillChange }))) : null,
        selectedReport.date === DATE_OPTIONS.RANGE ? (React.createElement(React.Fragment, null,
            React.createElement(Box, { display: 'flex', gap: 1, sx: { '& .MuiTextField-root': { width: '25ch' } } },
                React.createElement(DateTimePicker, { disableFuture: true, format: 'dd/MM/yyyy HH:mm:ss', ampm: false, label: 'Date from', slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today'],
                        },
                        textField: {
                            size: 'small',
                        },
                    }, views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'], value: dateFrom, onChange: handleDateFromChange })),
            React.createElement(Box, { display: 'flex', gap: 1, sx: { '& .MuiTextField-root': { width: '25ch' } } },
                React.createElement(DateTimePicker, { disableFuture: true, ampm: false, label: 'Date untill', format: 'dd/MM/yyyy HH:mm:ss', value: dateTill, slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today'],
                        },
                        textField: {
                            size: 'small',
                        },
                    }, views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'], onChange: handleDateTillChange })))) : null,
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end' } },
            React.createElement(LoadingButton, { loading: loading, variant: 'contained', onClick: handleDownload }, "Download"))));
};
export default ReportByID;
