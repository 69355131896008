import React, { useState, useEffect, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import { Box, Container, List } from '@mui/material';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialPopoverWrap, WalletsClientContainer, } from '../../styling/style';
import { getWidgetColumns, getCustodyWallets, } from '../../../../../redux/selectors/custodySelectors';
import AddressTableCell from './AddressTableCell';
import WithdrawalFilters from './WithdrawalFilters';
import AddressesCellActions from './AddressesCellActions';
import WithdrawalAddressModal from './WithdrawalAddressModal';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { AddressDelete, AddressSign, ManualAmlCheck } from './components/AddressesActions';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const forbidSorting = ['state', 'wallet_label', 'memo'];
const WidgetAddresses = ({ masterTenant, meCode, features, selectedClient, clientInfo, columns, walletsLoading, allWallets, addressesWidgetHeight, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [totalPages, setTotalPages] = useState(0);
    const [clientCode, setClientCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [urlAddresses, setUrlAddresses] = useState('');
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [sortBy, setSortBy] = useState('created_at');
    const [loadingWithdrawal, setLoadingWithdrawal] = useState(false);
    const [sortDirection, setSortDirection] = useState('desc');
    const [addresses, setAddresses] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [addressesToRender, setAddressesToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const updateUrl = useCallback((url) => {
        setUrlAddresses(url);
    }, [urlAddresses]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllAddresses = (param, cancelToken) => {
        setLoadingAddresses(true);
        const url = `/custody/withdrawals/addresses${param}`;
        const service = new WithdrawalsAddressesService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((data) => {
            setLoadingAddresses(false);
            setTotalPages(+data.total);
            setAddresses(data.records);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoadingAddresses(false);
            setTotalPages(0);
            setAddresses([]);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onChangePage = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleNewWithdrawalAddress = (payload, url) => {
        setLoadingWithdrawal(true);
        const service = new WithdrawalsAddressesService({
            url: '/custody/withdrawals/addresses', method: 'post', data: payload,
        });
        service.makeRequest()
            .then(() => {
            fetchAllAddresses(url);
            setLoadingWithdrawal(false);
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_CREATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingWithdrawal(false);
            toggleModal();
        });
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const cellActions = {
        actions: {
            sign: () => fetchAllAddresses(urlAddresses),
            approve: () => fetchAllAddresses(urlAddresses),
            confirm: () => fetchAllAddresses(urlAddresses),
            masterSign: () => fetchAllAddresses(urlAddresses),
            deleteItem: () => fetchAllAddresses(urlAddresses),
            aml: () => undefined,
        },
        actionsComponents: {
            sign: AddressSign,
            aml: ManualAmlCheck,
            approve: AddressSign,
            confirm: AddressDelete,
            masterSign: AddressSign,
            deleteItem: AddressDelete,
        },
        actionsList: [
            { name: 'Sign', action: 'sign' },
            { name: 'Approve', action: 'approve' },
            { name: 'Confirm', action: 'confirm' },
            { name: 'Delete', action: 'deleteItem' },
            { name: 'Add to manual AML', action: 'aml' },
            { name: 'Master sign', action: 'masterSign' },
        ],
    };
    useEffect(() => {
        setClientCode((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '');
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlAddresses !== '') {
            fetchAllAddresses(urlAddresses, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlAddresses]);
    useEffect(() => {
        if (addresses.length) {
            setAddressesToRender([...addresses]);
            if (!walletsLoading) {
                const mapped = addresses.map((item) => {
                    const wallet = allWallets.find(w => w.code === item.wallet_code);
                    const labelData = wallet ? wallet.label : 'N/A';
                    return Object.assign({}, item, { wallet_label: labelData });
                });
                setAddressesToRender([...mapped]);
            }
        }
        else {
            setTotalPages(0);
            setAddressesToRender([]);
        }
    }, [addresses, walletsLoading, allWallets]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(addressesWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [addressesWidgetHeight]);
    return (React.createElement("div", null,
        modalOpen ? (React.createElement(WithdrawalAddressModal, { open: modalOpen, toggleFunc: toggleModal, loading: loadingWithdrawal, urlAddresses: urlAddresses, handleNewWithdrawalAddress: handleNewWithdrawalAddress })) : null,
        React.createElement(WithdrawalFilters, { initialValue: "", sortBy: sortBy, page: currentPage, features: features, updateUrl: updateUrl, clientCode: clientCode, rowsPerPage: rowsPerPage, toggleModal: toggleModal, sortDirection: sortDirection, setCurrentPage: setCurrentPage }),
        loadingAddresses ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                        React.createElement(ViewColumnIcon, null)),
                    React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                        React.createElement(MaterialPopoverWrap, null,
                            React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                React.createElement(ListItemText, { primary: col.title }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
            addressesToRender.length === 0 ? (React.createElement(Container, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(React.Fragment, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, align: "left", style: { minWidth: column.minWidth } }, forbidSorting.includes(column.key) ? (React.createElement(TableSortLabel, null, column.title)) : (React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'desc', onClick: () => onSortHandle(column.key) }, column.title))))),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, addressesToRender.map(address => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: address.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? address[key] : undefined;
                                return (React.createElement(AddressTableCell, { key: i, value: value, columnKey: key }));
                            }),
                            React.createElement(AddressesCellActions, { item: address, meCode: meCode, features: features, admin: masterTenant, actions: cellActions, clientCode: clientCode, clientInfo: clientInfo }))))))),
                React.createElement(TablePagination, { component: "div", count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    meCode: state.client.meCode,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    allWallets: getCustodyWallets(state),
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    walletsLoading: state.custody.loadingWallets,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    addressesWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetAddresses);
