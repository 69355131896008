import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography, } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Ellipsis } from '@shared/components/styled/ellipsis';
const formSchema = yup
    .object({
    withdrawal_address_uuid: yup.string().required().default(''),
    liquidity_provider_uuid: yup.string().required().default(''),
    state: yup.string().required().oneOf(['active', 'inactive']).default('active'),
})
    .required();
const AddAddressDialog = (props) => {
    const { open, onClose, onAdd, providers, addressess } = props;
    const { handleSubmit, getValues, trigger, setValue, control, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    const handleClose = () => {
        onClose();
    };
    return (React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: 'sm', fullWidth: true, component: 'form', onSubmit: handleSubmit(onAdd) },
        React.createElement(DialogTitle, null, "Add Liquidity Provider"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2, py: 1 } },
                React.createElement(Controller, { name: 'withdrawal_address_uuid', control: control, render: ({ field }) => (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors['withdrawal_address_uuid'], InputLabelProps: { shrink: true }, required: true, label: 'Address', onChange: (e) => {
                            field.onChange(e.target.value);
                            setValue('withdrawal_address_uuid', e.target.value);
                            trigger('withdrawal_address_uuid');
                        } }), addressess === null || addressess === void 0 ? void 0 : addressess.map((o, i) => (React.createElement(MenuItem, { key: i, value: o.code },
                        React.createElement(Box, { sx: {
                                display: 'flex',
                                flex: 'auto',
                                gap: 1,
                                justifyContent: 'start',
                                alignItems: 'center',
                                overflow: 'hidden',
                            } },
                            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', flex: '1 0 auto' } },
                                React.createElement(Typography, { variant: 'subtitle1', component: 'div', fontWeight: 'bold' }, o.currency.toUpperCase()),
                                React.createElement(Typography, { variant: 'subtitle1', component: 'div' }, o.label),
                                React.createElement(Typography, { component: 'div', variant: 'body2' },
                                    React.createElement(Ellipsis, null, o.address))))))))) }),
                React.createElement(Controller, { name: 'liquidity_provider_uuid', control: control, render: ({ field }) => (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors['liquidity_provider_uuid'], InputLabelProps: { shrink: true }, required: true, label: 'Provider', onChange: (e) => {
                            field.onChange(e.target.value);
                            setValue('liquidity_provider_uuid', e.target.value);
                            trigger('liquidity_provider_uuid');
                        } }), providers === null || providers === void 0 ? void 0 : providers.map((o, i) => (React.createElement(MenuItem, { key: i, value: o.value }, o.label))))) }),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                    React.createElement(Controller, { name: 'state', control: control, render: ({ field }) => (React.createElement(Checkbox, Object.assign({}, field, { checked: getValues('state') === 'active', onChange: (e, checked) => {
                                field.onChange(checked);
                                setValue('state', checked ? 'active' : 'inactive');
                                trigger('state');
                            } }))) }),
                    React.createElement(Typography, { variant: 'body1' }, "Active")))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'contained', type: 'submit' }, "Create"),
            React.createElement(Button, { color: 'inherit', onClick: handleClose }, "Close"))));
};
export default AddAddressDialog;
