import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
const DLTNumberInput = (props) => {
    const { control, name, label, fullWidth, disabled } = props;
    return (React.createElement(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => {
            const { onChange, value, ref } = field;
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { type: 'number', ref: ref, disabled: disabled, error: !!error, fullWidth: fullWidth, label: label, size: 'small', value: value || '', onChange: (e) => {
                        onChange(e.target.value);
                    } })));
        } }));
};
export default DLTNumberInput;
