import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Decimal from 'decimal.js';
import copy from 'copy-to-clipboard';
import Icon from '@mui/material/Icon';
import { useDispatch } from 'react-redux';
import { Box, Button, Chip } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useResizeDetector } from 'react-resize-detector';
import { TableCell, Tooltip, TableRow } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SignDialog from './SignDialog';
import OrderInfoModal from './OrderInfoModal';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
import { selectCustomer } from '../../../../../../redux/actions/customersSearchActions';
import filterByObjectValues from '../../../../../../shared/helpers/filterByObjectValues';
import { Clickable, TruncateLeft } from '../../MergedOrderList/styling/styledComponents';
import getCustomerNameByCode from '../../../../../../shared/helpers/getCustomerNameByCode';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { ORDERS_ABORT_REQUEST, ORDERS_CANCEL_REQUEST } from '../../../../../../redux/actionTypes/orderBookActionTypes';
import { CUSTOMER_INFO_FETCH_REQUEST } from '../../../../../../redux/actionTypes/clientActionTypes';
import { SUBORDERS_ORDER_REMOVE_REQUEST } from '../../../../../../redux/actionTypes/subOrdersActionTypes';
import { DateCell } from '@shared/components/styled/tableCells';
import { format, parseISO } from 'date-fns';
const envSettings = getEnvSettings();
const getExchangeOption = (exchanges, code) => {
    const option = exchanges.find((exchange) => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
/* eslint-disable camelcase */
const OrderlistItem = ({ item, exchanges, features, customers, handleExchangeChange, meCode, masterTenant, }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [signModal, setSignModal] = useState(false);
    const [customerDetailsRead, setCustomerDetailsRead] = useState(true);
    const [customer, setCustomer] = useState(null);
    const { executed, created, action, market_item, type, amount, code, partial_order_uuid } = item;
    const [icon, setIcon] = useState('file_copy');
    const toggleInfoModal = () => {
        setModalOpen((prev) => !prev);
    };
    const toggleSignModal = () => {
        setSignModal((prev) => !prev);
    };
    const getOrderExecutionPrice = (order) => {
        if (order.type === 'Limit' && order.executed.price === '0') {
            return order.limit_price;
        }
        return order.executed.price;
    };
    const getTotalFeeValue = () => Decimal.add(executed.broker_fee, executed.bank_fee);
    const getAmountWithFees = () => {
        const executedDecimal = new Decimal(executed.value);
        if (action === 'Sell') {
            return executedDecimal.minus(getTotalFeeValue()).toFixed();
        }
        return executedDecimal.plus(getTotalFeeValue()).toFixed();
    };
    const handleCancelOrder = (dispatchCloseNotice) => {
        dispatch({ type: ORDERS_CANCEL_REQUEST, code, dispatchCloseNotice });
    };
    const handleRemoveOrder = () => {
        dispatch({
            type: SUBORDERS_ORDER_REMOVE_REQUEST,
            uid: partial_order_uuid,
            code,
        });
    };
    const handleAbortOrder = () => {
        dispatch({
            type: ORDERS_ABORT_REQUEST,
            dispatchCloseNotice: errorNotice,
            code,
        });
    };
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    const handleSelectCustomer = () => {
        if (customer && customerDetailsRead) {
            const selectedCustomer = Object.assign(Object.assign({}, customer), { label: customer.company_name });
            dispatch(selectCustomer(selectedCustomer));
            dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: customer.code });
        }
    };
    const handleAddExchange = (exchange) => {
        handleExchangeChange(exchange);
    };
    const cancelTypes = ['Market', 'RFQ_Market'];
    const cancelStatuses = ['Open', 'PendingSign'];
    const total = getAmountWithFees();
    const executedPrice = getOrderExecutionPrice(item);
    const exchangeName = useMemo(() => getExchangeOption(exchanges, market_item.exchange_code), [exchanges]);
    const actionClass = item.action === 'Buy' ? 'marketItem_bid_colors' : 'marketItem_ask_colors';
    useEffect(() => {
        if (customers === null || customers === void 0 ? void 0 : customers.length) {
            const newCustomersArrFiltered = filterByObjectValues(customers, item.customer_code);
            if (newCustomersArrFiltered[0]) {
                setCustomer(newCustomersArrFiltered[0]);
            }
            else {
                setCustomer(null);
            }
        }
    }, [customers]);
    useEffect(() => {
        if (features === null || features === void 0 ? void 0 : features.length) {
            const canReadDetails = features.includes('customer_details.read');
            setCustomerDetailsRead(canReadDetails);
        }
    }, [features]);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 10);
        }
    }, [width]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { key: created },
            React.createElement(TableCell, null,
                React.createElement("span", { className: actionClass }, action)),
            React.createElement(TableCell, null, type),
            React.createElement(TableCell, null,
                React.createElement("span", null,
                    React.createElement(NumericFormat, { value: new Decimal(amount).valueOf(), displayType: 'text', thousandSeparator: envSettings.thousandDivider }),
                    "\u00A0",
                    market_item.base)),
            React.createElement(TableCell, null,
                React.createElement(Box, { display: 'flex' },
                    React.createElement(Chip, { size: 'small', label: market_item.base }),
                    React.createElement(ChevronRightIcon, null),
                    React.createElement(Chip, { size: 'small', label: market_item.quote }))),
            React.createElement(TableCell, null,
                React.createElement(Clickable, { onClick: () => handleAddExchange(exchangeName) }, exchangeName)),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { displayType: 'text', value: new Decimal(executedPrice).toFixed(2), thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                market_item.quote),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { displayType: 'text', value: getAmountWithFees(), thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                market_item.quote),
            React.createElement(TableCell, null,
                React.createElement(DateCell, null, format(parseISO(created), 'dd-MM-yyyy')),
                ",\u00A0",
                React.createElement(DateCell, null, format(parseISO(created), 'HH:mm:ss'))),
            React.createElement(TableCell, null,
                React.createElement(Chip, { size: 'small', label: item.status === 'CanceledPartiallyFilled' ? 'Canceled Partially Filled' : item.status, style: statusBadgeColor(statusBadgeData.orderStatusBackgroundsRegular[item.status], statusBadgeData.orderStatusBordersRegular[item.status], statusBadgeData.orderStatusColorsRegular[item.status]).badge })),
            React.createElement(TableCell, { ref: ref },
                React.createElement(TruncateLeft, { width: `${cellWidth}` },
                    React.createElement(Clickable, { onClick: handleSelectCustomer }, getCustomerNameByCode(customers, item.customer_code)))),
            React.createElement(TableCell, { align: 'right', style: { whiteSpace: 'nowrap' } },
                item.reference_id ? (React.createElement(Icon, { onClick: () => handleCopy(`${item.reference_id}`), fontSize: 'small' }, icon)) : null,
                React.createElement(Tooltip, { title: item.reference_id || '', placement: 'bottom-start' },
                    React.createElement(TruncateLeft, { width: `${cellWidth}` }, item.reference_id))),
            React.createElement(TableCell, null,
                React.createElement(Box, { style: { display: 'flex' }, gap: 1 },
                    React.createElement(Button, { size: 'small', variant: 'outlined', style: { padding: 0 }, onClick: () => toggleInfoModal() }, "details"),
                    cancelStatuses.includes(item.status) && !cancelTypes.includes(item.type) && (React.createElement(Button, { variant: 'outlined', color: 'warning', style: { padding: 0 }, onClick: () => handleCancelOrder(errorNotice) }, "cancel")),
                    item.status === 'Prepared' && (React.createElement(Button, { color: 'error', variant: 'outlined', style: { padding: 0 }, onClick: () => handleRemoveOrder() }, "remove")),
                    item.status === 'Open' && (React.createElement(Permissions, { features: features, permissions: ['order_problem.update'] },
                        React.createElement(Button, { color: 'error', variant: 'outlined', style: { padding: 0 }, onClick: () => handleAbortOrder() }, "abort"))),
                    item.status === 'PendingSign' && item.creator_code !== meCode && !masterTenant && (React.createElement(Permissions, { features: features, permissions: ['order_sign.create'] },
                        React.createElement(Button, { variant: 'contained', color: 'primary', onClick: toggleSignModal, style: { padding: 0 } }, "sign")))))),
        modalOpen ? (React.createElement(OrderInfoModal, { item: item, total: total, open: modalOpen, customer: customer, features: features, code: item.customer_code, toggleFunc: toggleInfoModal })) : null,
        signModal ? React.createElement(SignDialog, { item: item, open: signModal, toggle: toggleSignModal }) : null));
};
export default OrderlistItem;
