import React from 'react';
import { Box, Container, Divider } from '@mui/material';
import LiquidityProvidersTable from './LiquidityProvidersTable';
import LiquidityProvidersAddressesTable from './LiquidityProvidersAddresses/LiquidityProvidersAddresses';
import { connect } from 'react-redux';
import { getCustodyCurrenciesOptions } from './../../../../../redux/selectors/currenciesSelectors';
const LiquidityProviders = (props) => {
    const { clientId, custodyCurrencies } = props;
    return (React.createElement(Box, { p: 1 },
        React.createElement(Container, { maxWidth: 'xl' },
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 3 } },
                React.createElement(Box, null,
                    React.createElement(LiquidityProvidersTable, null)),
                React.createElement(Divider, null),
                React.createElement(Box, null,
                    React.createElement(LiquidityProvidersAddressesTable, { clientId: clientId, custodyCurrencies: custodyCurrencies }))))));
};
const stateToProps = (state) => {
    var _a;
    const clientId = (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code;
    const custodyCurrencies = getCustodyCurrenciesOptions(state);
    return {
        clientId,
        custodyCurrencies,
    };
};
export default connect(stateToProps)(LiquidityProviders);
