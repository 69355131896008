import { styled } from 'styled-components';
export const IdCell = styled.span `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family:
    Martian Mono,
    monospace;
  font-size: 0.75rem;
  font-stretch: 90%;
  &:hover {
    cursor: pointer;
  };
  display: block;
  max-width: ${(props) => (props.$fullWidth ? '100%' : '100px')};
`;
export const AmountCell = styled.span `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-family:
    Martian Mono,
    monospace;
  font-size: 0.75rem;
  font-stretch: 90%;
`;
export const DateCell = styled.span `
  white-space: nowrap;
  font-family:
    Martian Mono,
    monospace;
  font-size: 0.75rem;
  font-stretch: 90%;
`;
export const TimeCell = styled.span `
  white-space: nowrap;
  font-family:
    Martian Mono,
    monospace;
  font-size: 0.625rem;
  font-stretch: 90%;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
`;
