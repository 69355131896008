import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import DLTFTable from '@shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '@shared/components/table/DLTTableModel';
import AddProviderDialog from './AddProviderDialog';
import { addProvider, fetchProviders } from './service';
const columns = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'code',
        label: 'Code',
    },
    {
        id: 'uuid',
        label: 'UUID',
    },
    {
        id: 'created_at',
        label: 'Created',
        type: CELL_TYPE.DATE_TIME,
    },
];
const mapProviders = (providers) => {
    return providers.map((p) => (Object.assign(Object.assign({}, p), { id: p.uuid })));
};
const LiquidityProvidersTable = () => {
    const [providers, setProviders] = useState([]);
    const [addProviderDialog, setAddProviderDialog] = useState(false);
    useEffect(() => {
        fetchProviders()
            .then((response) => {
            return response.data.records;
        })
            .then((providers) => {
            setProviders(mapProviders(providers));
        });
    }, []);
    const handleAddDialog = () => {
        setAddProviderDialog(!addProviderDialog);
    };
    const handleAddProvider = (params) => {
        addProvider(params).then(() => {
            setAddProviderDialog(!addProviderDialog);
            fetchProviders().then((response) => {
                setProviders(mapProviders(response.data.records));
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                React.createElement(Typography, { variant: 'h6', p: 1 }, "Liquidity Providers"),
                React.createElement(Button, { variant: 'contained', size: 'small', onClick: handleAddDialog }, "Add LP"))),
        addProviderDialog ? (React.createElement(AddProviderDialog, { open: addProviderDialog, onClose: handleAddDialog, onAdd: handleAddProvider })) : null,
        React.createElement(DLTFTable, { total: providers.length, data: providers, columns: columns, pagination: true })));
};
export default LiquidityProvidersTable;
