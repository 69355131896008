import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IdCell } from '../styled/tableCells';
import CopyValue from '../CopyValueComponent';
const Code = (props) => {
    const { code } = props;
    return (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
        React.createElement(Tooltip, { title: code, placement: 'top-start' },
            React.createElement(IdCell, null, code)),
        props.canCopy && React.createElement(CopyValue, { value: code })));
};
export default Code;
