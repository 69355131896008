import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
const formSchema = yup
    .object({
    code: yup.string().required().default(''),
    name: yup.string().required().default(''),
})
    .required();
const AddProviderDialog = (props) => {
    const { open, onClose, onAdd } = props;
    const { handleSubmit, register, trigger, setValue, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    const handleClose = () => {
        onClose();
    };
    return (React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: 'sm', fullWidth: true, component: 'form', onSubmit: handleSubmit(onAdd) },
        React.createElement(DialogTitle, null, "Add Liquidity Provider"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2, py: 1 } },
                React.createElement(TextField, Object.assign({ InputLabelProps: { shrink: true }, error: !!errors['code'], size: 'small', required: true, label: 'Code' }, register('code'), { onChange: (e) => {
                        setValue('code', e.target.value);
                        trigger('code');
                    } })),
                React.createElement(TextField, Object.assign({ InputLabelProps: { shrink: true }, error: !!errors['name'], size: 'small', required: true, label: 'Name' }, register('name'), { onChange: (e) => {
                        setValue('name', e.target.value);
                        trigger('name');
                    } })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'contained', type: 'submit' }, "Create"),
            React.createElement(Button, { color: 'inherit', onClick: handleClose }, "Close"))));
};
export default AddProviderDialog;
