import { Box, Button, Icon, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Permissions from '../../Layout/AdminSidebar/Permissions';
import { SIDE_MENU } from './navigationMenu';
import { connect } from 'react-redux';
import FeatureAccess from './FeatureAccess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { readFromLocalStorage, writeToLocalStorage } from '../../../shared/helpers/localStorageInterface';
import { SIDE_MENU_UPDATE } from '../../../redux/actionTypes/workspaceActions';
export const sideMenuWidthCollapsed = 100;
export const sideMenuWidthFull = 200;
const MenuItem = ({ item, subMenuLevel = 0, orientation = 'row', features, collapsed }) => {
    var _a;
    const [subMenu, setSubMenu] = useState();
    const theme = useTheme();
    useEffect(() => {
        const storedSubMenu = readFromLocalStorage('subMenuState');
        setSubMenu(storedSubMenu && storedSubMenu[item.id] !== undefined ? storedSubMenu[item.id] : true);
    }, []);
    const handleMenuClick = () => {
        if (item.children) {
            const storedSubMenu = readFromLocalStorage('subMenuState');
            const newValue = storedSubMenu ? Object.assign(Object.assign({}, storedSubMenu), { [item.id]: !subMenu }) : { [item.id]: !subMenu };
            writeToLocalStorage('subMenuState', newValue);
            setSubMenu(!subMenu);
        }
    };
    return (React.createElement(FeatureAccess, { hiddenFor: item.hiddenFor },
        React.createElement(Permissions, { oneOf: item.permissions && item.permissions.length > 1, permissions: [...(item.permissions || [])], features: features },
            React.createElement(Box, null,
                React.createElement(NavLink, { onClick: (e) => {
                        handleMenuClick();
                        if (item.children) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }, to: item.route, style: { color: theme.palette.text.primary, cursor: 'pointer' }, activeStyle: { color: theme.palette.primary.main } },
                    React.createElement(Box, { p: 1, sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: collapsed ? 'center' : 'start',
                            alignItems: 'center',
                            '&:hover': {
                                transition: '0.2s color',
                                color: theme.palette.primary.dark,
                            },
                        }, gap: orientation === 'row' && !collapsed ? 1 : 0 },
                        !item.isRoot ? (React.createElement(Icon, { className: 'material-icons-outlined', style: { fontSize: 24 } }, item.icon || 'folder')) : null,
                        React.createElement(Box, { sx: {
                                display: 'flex',
                                justifyContent: collapsed ? 'center' : 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            } },
                            !item.isRoot && !collapsed ? (React.createElement(Typography, { variant: item.isRoot ? 'overline' : 'body2', textAlign: collapsed ? 'center' : 'start' }, item.label)) : null,
                            item.isRoot && (React.createElement(Typography, { variant: item.isRoot ? 'overline' : 'body2', textAlign: collapsed ? 'center' : 'start' }, item.label)),
                            !collapsed && item.children ? subMenu ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null) : null))),
                item.children && item.children.length > 0 && subMenu ? (React.createElement(Box, { gap: 1, pl: collapsed ? 0 : 2, sx: {
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: collapsed ? 'center' : 'flex-start',
                    } }, (_a = item.children) === null || _a === void 0 ? void 0 : _a.map((subMenuItem, index) => (React.createElement(MenuItem, { index: index, key: subMenuItem.id, item: subMenuItem, subMenuLevel: subMenuLevel + 1, features: features, collapsed: collapsed }))))) : null))));
};
const SideNavigationComponent = (props) => {
    const menuSavedState = JSON.parse(localStorage.getItem('sideMenuCollapsed') || 'false');
    const theme = useTheme();
    const { onCollapse, balancesWidget } = props;
    const [collapsed, setCollapsed] = useState(menuSavedState);
    const handleCollapse = () => {
        setCollapsed(!collapsed);
        onCollapse(!collapsed);
        localStorage.setItem('sideMenuCollapsed', JSON.stringify(!collapsed));
        props.dispatchUpdateSideMenu(!collapsed);
    };
    useEffect(() => {
        onCollapse(collapsed);
    }, []);
    return (React.createElement(Box, { sx: {
            zIndex: 1,
            position: 'fixed',
            top: 0,
            paddingTop: (balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned) ? '120px' : '60px',
            height: '100vh',
            width: collapsed ? sideMenuWidthCollapsed : sideMenuWidthFull,
        } },
        React.createElement(Box, { sx: {
                width: '100%',
                height: '100%',
                overflow: 'scroll',
                scrollbarWidth: 0,
                borderRadius: 1,
                backgroundColor: theme.palette.background.paper,
            }, mt: 1, py: 2, px: collapsed ? 0 : 2 },
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 1, pb: 10 }, SIDE_MENU.map((item, index) => (React.createElement(MenuItem, { key: item.id, item: item, orientation: 'row', features: props.features, index: index, collapsed: collapsed })))),
            React.createElement(Box, { p: 2, sx: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderTop: `3px solid ${theme.palette.secondary.dark}`,
                    backgroundColor: theme.palette.background.paper,
                    display: 'flex',
                    justifyContent: 'center',
                } },
                React.createElement(Button, { variant: 'text', onClick: () => handleCollapse() }, !collapsed ? (React.createElement(React.Fragment, null,
                    React.createElement(ChevronLeftIcon, null),
                    "Collapse")) : (React.createElement(ChevronRightIcon, null)))))));
};
const dispatchToProps = (dispatch) => ({
    dispatchUpdateSideMenu: (collapsed) => dispatch({ type: SIDE_MENU_UPDATE, collapsed }),
});
const stateToProps = (state) => {
    const { features, role } = state.client;
    const { balances } = state.workspace;
    return {
        role,
        features,
        balancesWidget: balances,
    };
};
export default connect(stateToProps, dispatchToProps)(SideNavigationComponent);
