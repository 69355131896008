import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import DLTAutocomplete from '@shared/components/form/DLTAutocomplete';
import axios from 'axios';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import showNotification from '@shared/helpers/notifications';
import { composeErrorMessage } from '@shared/helpers/interceptors';
import { useDispatch } from 'react-redux';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { LoadingButton } from '@mui/lab';
const DeclineAllSetllments = ({ type, open, onClose, clientsOptions, customersOptions, clientId }) => {
    const { control, getValues, reset, resetField } = useForm({
        mode: 'onChange',
    });
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDeclineAll = () => {
        setLoading(true);
        const params = getValues();
        if (clientId) {
            params.client_code = clientId;
        }
        declineAll(params)
            .then(() => {
            showNotification({
                message: `All settlements were declined`,
                color: 'success',
                dispatch: errorNotice,
            });
            onClose();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, 'Failed to decline settlements');
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        }).finally(() => {
            setLoading(false);
        });
    };
    const declineAll = (params) => {
        return axios.post('/settlements/accounts/decline_all', Object.assign({ settlement_type: type }, params), { baseURL: getEnvSettings().adminApiUrl });
    };
    return (React.createElement(Dialog, { open: open, onClose: () => onClose(), fullWidth: true },
        React.createElement(DialogTitle, null, "Decline All Settlements"),
        React.createElement(DialogContent, null,
            "Are you sure to decline all settlements?",
            React.createElement(Box, { py: 1, gap: 2, display: 'flex', flexDirection: 'column' },
                clientsOptions && type === 'account' && (React.createElement(DLTAutocomplete, { fullWidth: false, control: control, name: 'client_code', label: 'Client', options: clientsOptions })),
                customersOptions && type === 'client' && (React.createElement(DLTAutocomplete, { fullWidth: false, control: control, name: 'customer_code', label: 'Customer', options: customersOptions })))),
        React.createElement(DialogActions, null,
            React.createElement(LoadingButton, { loading: loading, color: 'error', variant: 'contained', onClick: handleDeclineAll }, "Decline"),
            React.createElement(Button, { onClick: () => onClose(), color: 'inherit' }, "Cancel"))));
};
export default DeclineAllSetllments;
