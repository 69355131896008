import { PERMISSIONS } from '../../../shared/constants/Permissions';
export const SIDE_MENU = [
    {
        id: 'manage',
        route: '/manage',
        label: 'Manage',
        collapsed: true,
        icon: 'manage_accounts',
        isRoot: true,
        children: [
            {
                id: 'clients',
                label: 'Clients',
                collapsed: false,
                icon: 'supervisor_account',
                route: '/manage/clients',
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'customers',
                label: 'Customers',
                collapsed: false,
                icon: 'people',
                route: '/manage/customers',
                hiddenFor: ['customer'],
                permissions: [PERMISSIONS.CUSTOMER_READ],
            },
            {
                id: 'users',
                label: 'Users',
                collapsed: false,
                icon: 'account_box',
                route: '/manage/users',
                hiddenFor: ['customer'],
                permissions: [PERMISSIONS.USERS_READ],
            },
            {
                id: 'permissions',
                label: 'Permissions',
                collapsed: false,
                icon: 'lock_person',
                route: '/manage/permissions',
                hiddenFor: ['client', 'customer'],
                permissions: [PERMISSIONS.ROLES_PERMISSIONS_READ],
            },
            {
                id: 'balances',
                label: 'Balances',
                collapsed: false,
                icon: 'account_balance',
                route: '/manage/balances',
            },
        ],
    },
    {
        id: 'operations',
        label: 'Operations',
        collapsed: true,
        icon: 'construction',
        route: '/operations',
        isRoot: true,
        children: [
            {
                id: 'orders',
                label: 'Orders',
                collapsed: false,
                icon: 'shopping_cart_checkout',
                permissions: ['order.read'],
                route: '/operations/orders',
            },
            {
                id: 'reports',
                label: 'Reports',
                collapsed: false,
                icon: 'feed',
                route: '/operations/reports',
                oneOf: true,
                permissions: [
                    'custody_report.create',
                    'fee_report.create',
                    'report.create',
                    'report_trading.create',
                    'report.read',
                    'report_trading.read',
                ],
            },
            {
                id: 'transfers',
                label: 'Transfers',
                collapsed: false,
                icon: 'move_down',
                route: '/operations/transfers',
                permissions: ['trading_transfers.read'],
            },
            {
                id: 'settlements',
                label: 'Settlements',
                collapsed: false,
                icon: 'multiple_stop',
                route: '/operations/settlements',
                hiddenFor: ['client', 'customer'],
                children: [
                    {
                        id: 'settlements_general',
                        label: 'Regular',
                        collapsed: false,
                        route: '/operations/customers-settlements',
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'settlements_etoro',
                        label: 'Etoro',
                        collapsed: false,
                        route: '/operations/clients-settlements',
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'settlements_nexus',
                        label: 'Nexus',
                        collapsed: false,
                        route: '/operations/nexus-settlements',
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'settlements_baader',
                        label: 'Baader',
                        collapsed: false,
                        route: '/operations/baader-settlements',
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'settlements_lp',
                        label: 'LP',
                        collapsed: false,
                        route: '/operations/lp-settlements',
                        hiddenFor: ['client', 'customer'],
                    },
                ],
            },
            {
                id: 'schedulers',
                label: 'Schedulers',
                collapsed: false,
                icon: 'query_builder',
                route: '/operations/schedulers',
                hiddenFor: ['client', 'customer'],
            },
        ],
    },
    {
        id: 'settings',
        label: 'Settings',
        collapsed: true,
        icon: 'tune',
        route: '/settings',
        isRoot: true,
        children: [
            {
                id: 'rfq',
                label: 'RFQ Streams',
                collapsed: false,
                icon: 'stream',
                route: '/settings/rfq',
                permissions: ['client_rfq_settings.read'],
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'settlement_lines',
                label: 'Settlement Lines',
                collapsed: false,
                icon: 'handshake',
                route: '/settings/settlement-lines',
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'api_tokens',
                label: 'API Tokens',
                collapsed: false,
                icon: 'code',
                route: '/settings/api-tokens',
                permissions: ['api_tokens.read'],
            },
            {
                id: 'webhooks',
                label: 'Webhooks',
                collapsed: false,
                icon: 'webhook',
                route: '/settings/webhooks',
            },
            {
                id: 'funding_info',
                label: 'Funding Info',
                collapsed: false,
                icon: 'account_balance',
                route: '/settings/funding-info',
            },
        ],
    },
    {
        id: 'admin',
        label: 'Admin',
        collapsed: true,
        icon: 'admin_panel_settings',
        route: '/admin',
        isRoot: true,
        hiddenFor: ['client', 'customer'],
        children: [
            {
                id: 'compliance',
                label: 'Compliance',
                collapsed: false,
                icon: 'shield',
                route: '/admin/compliance',
            },
            {
                id: 'exchanges',
                label: 'Exchanges',
                collapsed: false,
                icon: 'stacked_line_chart',
                route: '/admin/exchanges',
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'risk_manager',
                label: 'Risk Manager',
                collapsed: false,
                icon: 'crisis_alert',
                route: '/admin/risk-manager',
                hiddenFor: ['client', 'custmomer'],
            },
            {
                id: 'autohedger_ordeer',
                label: 'Autohedger Order',
                collapsed: false,
                icon: 'auto_mode',
                route: '/admin/autohedger-order',
                permissions: ['admin_hedge_order.create'],
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'liquidity_providers',
                label: 'Liquidity providers',
                collapsed: false,
                icon: 'handshake',
                route: '/admin/liquidity-providers',
                hiddenFor: ['client', 'customer'],
            },
            {
                id: 'currency_config',
                label: 'Currency Config',
                collapsed: false,
                icon: 'tune',
                route: '/admin/currency-config',
                permissions: ['custody_currency_configuration.read'],
                hiddenFor: ['client', 'customer'],
                children: [
                    {
                        id: 'trading_currencies',
                        label: 'Trading Currencies',
                        collapsed: false,
                        icon: 'currency_bitcoin',
                        route: '/admin/trading-currencies',
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'currency_config',
                        label: 'Custody Currencies',
                        collapsed: false,
                        icon: 'currency_bitcoin',
                        route: '/admin/currency-config',
                        permissions: ['custody_currency_configuration.read'],
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'price_feed',
                        label: 'Price Feeds',
                        collapsed: false,
                        icon: 'price_change',
                        route: '/admin/price-feed',
                        permissions: ['custody_currency_configuration.read'],
                        hiddenFor: ['client', 'customer'],
                    },
                    {
                        id: 'currency_networks',
                        label: 'Networks',
                        collapsed: false,
                        icon: 'hub',
                        route: '/admin/currency-networks',
                        permissions: ['custody_currency_configuration.read'],
                        hiddenFor: ['client', 'customer'],
                    },
                ],
            },
            {
                id: 'defi_strategies',
                label: 'DeFi Strategies',
                collapsed: false,
                icon: 'layers',
                route: '/admin/defi-strategies',
                hiddenFor: ['client', 'customer'],
            },
        ],
    },
];
