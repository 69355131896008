import axios from 'axios';
import { getEnvSettings } from './../../../../../../config/environmentSettings';
import { composeErrorMessage } from '@shared/helpers/interceptors';
import showNotification from '@shared/helpers/notifications';
export function makeFilters(currencies, providers) {
    const states = {
        name: 'states',
        label: 'State',
        values: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ],
    };
    const currency_codes = {
        name: 'currency_codes',
        label: 'Currency',
        values: currencies,
    };
    const liquidity_provider_uuids = {
        name: 'liquidity_provider_uuids',
        label: 'Provider',
        values: providers,
    };
    return [states, currency_codes, liquidity_provider_uuids];
}
export const fetchAddresses = (filterParams) => {
    var _a;
    return axios.get('liquidity_provider/address', {
        params: Object.assign(Object.assign({}, filterParams), { currency_codes: (_a = filterParams.currency_codes) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase() }),
        baseURL: getEnvSettings().adminApiUrl,
    });
};
export const addLPAddress = (params) => {
    return axios
        .post('/liquidity_provider/address', params, { baseURL: getEnvSettings().adminApiUrl })
        .then(() => {
        showNotification({ message: 'Liquidity provider address has been successfuly created', color: 'success' });
    })
        .catch((e) => {
        const message = composeErrorMessage(e, 'Failed to create liquidity provider address');
        showNotification({ message, color: 'error' });
    });
};
export const fetchWithdrawalAddresses = (clientId) => {
    return axios.get(`custody/withdrawals/addresses?clientCode=${clientId}&state=approved`);
};
export const updateAddress = (addressUUID, newState) => {
    return axios
        .patch(`liquidity_provider/address/${addressUUID}`, { state: newState }, { baseURL: getEnvSettings().adminApiUrl }).then(() => {
        showNotification({ message: 'Status has been successfully updated', color: 'success' });
    })
        .catch((e) => {
        const message = composeErrorMessage(e, 'Failed to update liquidity provider address');
        showNotification({ message, color: 'error' });
    });
};
