import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DLTAutocomplete from '../../../../../shared/components/form/DLTAutocomplete';
import axios from 'axios';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import showNotification from '../../../../../shared/helpers/notifications';
import Messages from '../../../../../shared/helpers/errorMessages';
import { connect } from 'react-redux';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SEARCH_SET_CLIENT, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
import { DateTimePicker } from '@mui/x-date-pickers';
const setCustomersClient = (client) => ({
    type: CUSTOMER_SEARCH_SET_CLIENT,
    selectedClient: client,
});
const InitiateSettlements = (props) => {
    var _a, _b;
    const { customers, clients, currencies, dispatchClientSelect, depositHolder, withDates, settlementType, clientSettlementId, } = props;
    const [liquidityProviders, setLiquidityProviders] = useState([]);
    const formSchema = yup.object({
        currency: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        client: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        customer: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        from: withDates ? yup.date().required() : yup.date().nullable(),
        to: withDates ? yup.date().required() : yup.date().nullable(),
        liquidity_provider_codes: settlementType === 'lp'
            ? yup.lazy((val) => (Array.isArray(val) ? yup.array().of(yup.string()) : yup.string()))
            : yup.array().nullable(),
    });
    const { handleSubmit, control, formState, getValues, setValue, trigger } = useForm({
        defaultValues: formSchema.cast({
            currency: null,
            client: null,
            customer: null,
            from: new Date(new Date().setSeconds(0, 0)),
            to: new Date(new Date().setSeconds(0, 0)),
            liquidity_provider_codes: [],
        }),
        resolver: yupResolver(formSchema),
    });
    const [loading, setLoading] = useState(false);
    const selectedClient = useWatch({ control, name: 'client' });
    const handleInitiateSettlements = (data) => {
        let dateFrom = null;
        let dateTo = null;
        if (withDates && data.from && data.to) {
            dateFrom = data.from.toISOString();
            dateTo = data.to.toISOString();
        }
        setLoading(true);
        const params = {
            deposit_holder_code: withDates ? undefined : data.client || data.customer ? undefined : depositHolder,
            client_code: withDates ? clientSettlementId : data.client,
            customer_code: data.customer || undefined,
            currency_code: data.currency || undefined,
            from: dateFrom || undefined,
            to: dateTo || undefined,
            type: settlementType,
            liquidity_provider_codes: (typeof data.liquidity_provider_codes === 'string'
                ? [data.liquidity_provider_codes]
                : data.liquidity_provider_codes && data.liquidity_provider_codes.length > 0
                    ? data.liquidity_provider_codes
                    : undefined) || undefined,
        };
        axios
            .post('/settlements/accounts/create_manually', params, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            props.toggleDialog(true);
            showNotification({
                message: `Settlements were initiated`,
                color: 'success',
            });
        })
            .catch((e) => {
            var _a;
            const errorCode = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data.code;
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_INITIATE);
            showNotification({
                message: `Error: ${message}`,
                color: errorCode === 9100 ? 'warning' : 'error',
            });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (selectedClient !== undefined && !withDates) {
            dispatchClientSelect(selectedClient);
        }
    }, [selectedClient]);
    useEffect(() => {
        if (settlementType === 'lp') {
            getLiquidityProviders().then((res) => {
                setLiquidityProviders(res.data.records);
            });
        }
    }, []);
    const getLiquidityProviders = () => {
        return axios.get('/liquidity_provider', {
            baseURL: getEnvSettings().adminApiUrl,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogTitle, null, "Initiate Settlements"),
        React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => handleInitiateSettlements(data)) },
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
                    settlementType === 'lp' ? (React.createElement(React.Fragment, null,
                        React.createElement(Controller, { name: 'liquidity_provider_codes', control: control, render: ({ field }) => (React.createElement(FormControl, { sx: { display: 'flex' }, size: 'small' },
                                React.createElement(InputLabel, { id: 'lp' }, "Liquidity provider"),
                                React.createElement(Select, Object.assign({ labelId: 'lp', fullWidth: true, multiple: true }, field, { input: React.createElement(OutlinedInput, { label: 'Liquidity provider' }), size: 'small', onChange: (e) => {
                                        field.onChange(e.target.value);
                                        setValue('liquidity_provider_codes', e.target.value);
                                        trigger('liquidity_provider_codes');
                                    } }), liquidityProviders.map((o, i) => (React.createElement(MenuItem, { key: i, value: o.uuid }, o.name)))))) }))) : null,
                    !withDates ? (React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, gap: 2 },
                        React.createElement(DLTAutocomplete, { fullWidth: true, name: 'currency', label: 'Currency', options: currencies, control: control }),
                        React.createElement(DLTAutocomplete, { fullWidth: true, label: 'Client', name: 'client', options: clients, control: control }),
                        React.createElement(DLTAutocomplete, { disabled: !selectedClient, fullWidth: true, label: 'Customer', name: 'customer', options: customers, control: control }))) : (React.createElement(Box, { sx: { display: 'flex', gap: 2, width: '100%' } },
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', width: '50%' } },
                            React.createElement(Controller, { control: control, name: 'from', rules: { required: true }, render: ({ field }) => {
                                    return (React.createElement(DateTimePicker, { label: 'Date from', format: 'dd/MM/yyyy HH:mm', disableFuture: true, ampm: false, slotProps: { textField: { size: 'small' } }, value: field.value, inputRef: field.ref, onChange: (date) => {
                                            field.onChange(date);
                                            trigger('from');
                                        } }));
                                } }),
                            React.createElement(Typography, { variant: 'caption' }, (_a = getValues('from')) === null || _a === void 0 ? void 0 : _a.toUTCString())),
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', width: '50%' } },
                            React.createElement(Controller, { control: control, name: 'to', rules: { required: true }, render: ({ field }) => {
                                    return (React.createElement(DateTimePicker, { label: 'Date to', disableFuture: true, format: 'dd/MM/yyyy HH:mm', ampm: false, value: field.value, inputRef: field.ref, slotProps: { textField: { size: 'small' } }, onChange: (date) => {
                                            field.onChange(date);
                                            trigger('to');
                                        } }));
                                } }),
                            React.createElement(Typography, { variant: 'caption' }, (_b = getValues('to')) === null || _b === void 0 ? void 0 : _b.toUTCString())))))),
            React.createElement(DialogActions, null,
                React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained', disabled: !formState.isValid }, "Initiate"),
                React.createElement(Button, { onClick: () => props.toggleDialog(false) }, "Cancel")))));
};
const mapStateToProps = (state) => {
    var _a;
    const mappedCustomers = getCustomersSelectedInputInterface(state).map((c) => ({
        value: c.value,
        label: c.label,
    }));
    mappedCustomers.unshift({ value: null, label: 'All' });
    const mappedClients = state.clientSearch.allClients.map((c) => ({
        value: c.code,
        label: c.company_name,
    }));
    mappedClients.unshift({ value: null, label: 'All' });
    return {
        customers: mappedCustomers,
        clients: mappedClients,
        currencies: getCustodyCurrenciesOptions(state),
        depositHolder: ((_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.deposit_holder_code) || null,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(setCustomersClient(client)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InitiateSettlements);
