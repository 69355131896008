import React, { useEffect, useRef, useState } from 'react';
import DLTFTable from '@shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '@shared/components/table/DLTTableModel';
import { Box, Typography, Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DLTFTableFilters from '@shared/components/filters/DLTFTableFilters';
import AddAddressDialog from './AddAddressDialog';
import useConfirmationDialog from '@shared/components/confirmationDialog/useConfirmationDialog';
import ConfirmationDialog from '@shared/components/confirmationDialog/ConfirmationDialog';
import { addLPAddress, fetchAddresses, fetchWithdrawalAddresses, makeFilters, updateAddress } from './service';
import { fetchProviders } from '../service';
const columns = [
    {
        id: 'state',
        label: 'State',
    },
    {
        id: 'liquidity_provider',
        label: 'Provider',
    },
    {
        id: 'currency_code',
        label: 'Currency',
    },
    {
        id: 'address',
        label: 'Address',
    },
    {
        id: 'created_at',
        label: 'Created',
        type: CELL_TYPE.DATE_TIME,
    },
];
const mapAddresses = (addresses, withdrawalAddresses, callback) => {
    return addresses.map((a) => {
        var _a;
        return (Object.assign(Object.assign({}, a), { id: a.uuid, liquidity_provider: a.liquidity_provider.name, address: (_a = withdrawalAddresses.find((wa) => wa.code === a.client_custody_withdrawal_address_uuid)) === null || _a === void 0 ? void 0 : _a.address, state: a.state === 'active' ? (React.createElement(CircleIcon, { sx: { cursor: 'pointer' }, color: 'success', onClick: () => {
                    callback ? callback(a.uuid, 'inactive') : null;
                } })) : (React.createElement(CircleIcon, { sx: { cursor: 'pointer' }, color: 'error', onClick: () => {
                    callback ? callback(a.uuid, 'active') : null;
                } })) }));
    });
};
const mapProviders = (providers) => {
    return providers.map((p) => (Object.assign(Object.assign({}, p), { id: p.uuid })));
};
const LiquidityProvidersAddressesTable = (props) => {
    const { clientId, custodyCurrencies } = props;
    const [filters, setFilters] = useState([]);
    const [addAddressDialog, setAddAddressDialog] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [addressesOptions, setAddressesOptions] = useState([]);
    const [providers, setProviders] = useState([]);
    const [providersOptions, setProvidersOptions] = useState([]);
    const [confirmDialogData, setConfirmDialogData] = useState();
    const { isConfirmOpen, openConfirmDialog, closeConfirmDialog } = useConfirmationDialog();
    const [filterParams, setFilterParams] = useState({
        currency_codes: undefined,
        liquidity_provider_uuids: undefined,
        states: undefined,
    });
    const prevFilters = useRef(filterParams).current;
    const handleAddAddressDialog = () => {
        setAddAddressDialog(!addAddressDialog);
    };
    const handleFiltersChange = (e) => {
        setFilterParams(e);
    };
    const handleFiltersReset = () => {
        setFilterParams({});
    };
    const handleAddAddress = (params) => {
        addLPAddress(params).then(() => {
            setAddAddressDialog(!addAddressDialog);
            fetchAddresses(filterParams).then((response) => {
                setAddresses(mapAddresses(response.data.records, addressesOptions, handleOpenConfirm));
            });
        });
    };
    const handleUpdateAddress = () => {
        if (!confirmDialogData) {
            return;
        }
        updateAddress(confirmDialogData === null || confirmDialogData === void 0 ? void 0 : confirmDialogData.uuid, confirmDialogData === null || confirmDialogData === void 0 ? void 0 : confirmDialogData.state).then(() => {
            closeConfirmDialog();
            fetchAddresses(filterParams).then((response) => {
                setAddresses(mapAddresses(response.data.records, addressesOptions, handleOpenConfirm));
            });
        });
    };
    const handleOpenConfirm = (uuid, state) => {
        setConfirmDialogData({ uuid, state });
        openConfirmDialog();
    };
    const handleConfirmation = () => {
        if (!confirmDialogData) {
            closeConfirmDialog();
            return;
        }
        handleUpdateAddress();
    };
    useEffect(() => {
        if (!clientId) {
            return;
        }
        Promise.all([
            fetchWithdrawalAddresses(clientId).then((response) => {
                return response.data.records;
            }),
            fetchProviders().then((response) => {
                return response.data.records;
            }),
            fetchAddresses(filterParams).then((response) => {
                return response.data.records;
            }),
        ]).then(([withdrawalAddresses, providers, LPAddresses]) => {
            setAddressesOptions(withdrawalAddresses);
            setProviders(mapProviders(providers));
            setAddresses(mapAddresses(LPAddresses, withdrawalAddresses, handleOpenConfirm));
        });
    }, []);
    useEffect(() => {
        const options = providers.map((p) => ({
            value: p.id,
            label: p.name,
        }));
        if (custodyCurrencies && providers) {
            setFilters(makeFilters(custodyCurrencies, options));
        }
        setProvidersOptions(options);
    }, [providers, custodyCurrencies]);
    useEffect(() => {
        if (prevFilters !== filterParams) {
            fetchAddresses(filterParams).then((response) => {
                setAddresses(mapAddresses(response.data.records, addressesOptions, handleOpenConfirm));
            });
        }
    }, [filterParams]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'h6', p: 1 }, "Liquidity Providers Addresses"),
            React.createElement(Button, { variant: 'contained', size: 'small', onClick: handleAddAddressDialog }, "Add Address")),
        React.createElement(Box, null, filters.length ? (React.createElement(DLTFTableFilters, { fields: filters, onChange: (e) => handleFiltersChange(e), onReset: handleFiltersReset })) : null),
        addAddressDialog ? (React.createElement(AddAddressDialog, { open: addAddressDialog, onClose: handleAddAddressDialog, onAdd: handleAddAddress, providers: providersOptions, addressess: addressesOptions })) : null,
        React.createElement(ConfirmationDialog, { open: isConfirmOpen, title: 'Change address state', content: `Are you sure you want to change address state to ${confirmDialogData === null || confirmDialogData === void 0 ? void 0 : confirmDialogData.state.toUpperCase()}?`, onClose: () => closeConfirmDialog(), onConfirm: () => handleConfirmation() }),
        React.createElement(DLTFTable, { total: addresses.length, data: addresses, columns: columns, pagination: true })));
};
export default LiquidityProvidersAddressesTable;
