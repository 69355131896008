/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect, useRef, } from 'react';
import { Box, Button, Tooltip, FormGroup, TextField, IconButton, FormControl, InputAdornment, } from '@mui/material';
import Decimal from 'decimal.js';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/lab/Autocomplete';
const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const optionalCustomerFields = [
    'iban', 'ibanEuro', 'ibanUSDollar', 'domain',
    'city', 'email', 'address', 'zipCode', 'country', 'companyName', 'phoneNumber', 'contactPersonName',
];
const optionalClientFields = [
    'ibanEuro', 'ibanUSDollar',
];
const editableFields = [
    'address', 'city', 'companyName', 'contactPersonName', 'country', 'email',
    'phoneNumber',
    'zipCode',
];
const validateFields = (fieldsToValidate, user) => {
    const errors = [];
    for (const [key, value] of Object.entries(fieldsToValidate)) {
        if (String(value).trim() === '') {
            if (user === 'customer' && !optionalCustomerFields.includes(key)) {
                errors.push(key);
            }
            else if (user === 'client' && !optionalClientFields.includes(key)) {
                errors.push(key);
            }
        }
        else if (key === 'email') {
            if (!regEx.test(String(value).toLowerCase())) {
                errors.push(key);
            }
        }
        else if (key === 'currency' && String(value).length !== 3) {
            errors.push(key);
        }
    }
    return errors;
};
const mapIbans = (iban, euro, usDollar, currency) => {
    const mappedIbans = [];
    if (iban !== '') {
        mappedIbans.push({ currency: currency || '', iban, is_enabled: true });
    }
    if (euro !== '') {
        mappedIbans.push({ currency: 'EUR', iban: euro, is_enabled: true });
    }
    if (usDollar !== '') {
        mappedIbans.push({ currency: 'USD', iban: usDollar, is_enabled: true });
    }
    return mappedIbans;
};
const currencies = [
    { label: 'Euro', value: 'EUR' },
    { label: 'US Dollar', value: 'USD' },
];
const defaultCustomerOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
];
const statusOptions = [
    { label: 'Enabled', value: '1' },
    { label: 'Disabled', value: '0' },
];
const clientFields = {
    iban: '',
    city: '',
    fee: '25',
    email: '',
    domain: '',
    address: '',
    zipCode: '',
    country: '',
    enabled: 'true',
    currency: 'EUR',
    companyName: '',
    phoneNumber: '',
    contactPersonName: '',
    defaultCustomer: 'true',
};
const customerFields = {
    enabled: 'true',
    currency: 'EUR',
};
const snakeToCamel = (str) => str.toLowerCase().replace(/([-_][a-z])/g, group => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));
const WithdrawalForm = ({ createClient, updateClient, toggleFunc, user, clientCode, clientDetails, }) => {
    var _a, _b, _c, _d, _e, _f;
    const clientDomain = clientDetails && 'domain' in clientDetails ? clientDetails.domain : '';
    const clientFee = clientDetails && 'fee' in clientDetails ? clientDetails.fee : '';
    const dirty = useRef(false);
    const [fee, setFee] = useState(clientFee || '25');
    const [city, setCity] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.city) || '');
    const [iban, setIban] = useState(((_b = (_a = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.ibans) === null || _a === void 0 ? void 0 : _a.find(i => i.currency === (clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.currency))) === null || _b === void 0 ? void 0 : _b.iban) || '');
    const [email, setEmail] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.email) || '');
    const [domain, setDomain] = useState(clientDomain);
    const [address, setAddress] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.address) || '');
    const [zipCode, setZipCode] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.zip_code) || '');
    const [country, setCountry] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.country) || '');
    const [ibanEuro, setIbanEuro] = useState(((_d = (_c = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.ibans) === null || _c === void 0 ? void 0 : _c.find(i => i.currency === 'EUR')) === null || _d === void 0 ? void 0 : _d.iban) || '');
    const [ibanUSDollar, setIbanUSDollar] = useState(((_f = (_e = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.ibans) === null || _e === void 0 ? void 0 : _e.find(i => i.currency === 'USD')) === null || _f === void 0 ? void 0 : _f.iban) || '');
    const [companyName, setCompanyName] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.company_name) || '');
    const [phoneNumber, setPhoneNumber] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.phone_number) || '');
    const [validation, setValidation] = useState([]);
    const [contactPersonName, setContactPersonName] = useState((clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.contact_person_name) || '');
    const [formFields, setFormFields] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [currency, setCurrency] = useState(currencies.find(c => c.value === (clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.currency))
        || currencies[0]);
    const [enabled, setEnabled] = useState(statusOptions[0]);
    const [emailErrText, setEmailErrText] = useState('');
    const [showEmailErr, setShowEmailErr] = useState(false);
    const [phoneErrText, setPhoneErrText] = useState('');
    const [showPhonelErr, setShowPhoneErr] = useState(false);
    useEffect(() => {
        if (validation.includes('email') && user === 'client') {
            setShowEmailErr(true);
            setEmailErrText('Email is required and should be valid');
        }
        else if (user === 'customer' && validation.includes('email')) {
            setShowEmailErr(true);
            setEmailErrText('Must be valid email address');
        }
        else if (validation.includes('email')) {
            setShowEmailErr(false);
            setEmailErrText('');
        }
        if (validation.includes('phoneNumber') && user === 'client') {
            setShowPhoneErr(true);
            setPhoneErrText('Phone number is required and should be at least 5 chars long');
        }
        else if (user === 'customer' && validation.includes('phoneNumber')) {
            setShowPhoneErr(true);
            setPhoneErrText('Phone number should be at least 5 chars long');
        }
        else if (validation.includes('phoneNumber')) {
            setShowPhoneErr(false);
            setPhoneErrText('');
        }
    }, [user, validation]);
    useEffect(() => {
        if (dirty.current && formFields) {
            setValidation(() => [...validateFields(formFields, user)]);
        }
    }, [formFields]);
    useEffect(() => {
        var _a, _b;
        const fields = Object.assign({}, clientFields);
        if (clientDetails) {
            Object.keys(clientDetails).forEach((key) => {
                const camelKey = snakeToCamel(key);
                if (camelKey in fields) {
                    fields[camelKey] = clientDetails[key];
                }
            });
            if ('iban' in fields) {
                fields.iban = ((_b = (_a = clientDetails.ibans) === null || _a === void 0 ? void 0 : _a.find(i => i.currency === clientDetails.currency)) === null || _b === void 0 ? void 0 : _b.iban) || '';
            }
        }
        setFormFields(Object.assign(Object.assign({}, formFields), fields));
    }, []);
    const handleCreate = () => {
        dirty.current = true;
        if (formFields) {
            const errors = validateFields(formFields, user);
            if (errors.length) {
                setValidation(() => [...errors]);
                return;
            }
            setValidation([]);
        }
        const mappedIbans = mapIbans(iban, ibanEuro, ibanUSDollar, (currency === null || currency === void 0 ? void 0 : currency.value) || '');
        const payload = {
            fee,
            city,
            email,
            domain,
            country,
            address,
            zip_code: zipCode,
            ibans: mappedIbans,
            client_code: clientCode,
            phone_number: phoneNumber,
            company_name: companyName,
            contact_person_name: contactPersonName,
            currency: currency ? currency.value : '',
            is_enabled: enabled ? !!+enabled.value : true,
            is_customer_required: defaultCustomer ? !!+defaultCustomer.value : true,
        };
        createClient(payload, setErrorMsg);
    };
    const handleUpdate = () => {
        dirty.current = true;
        if (formFields) {
            const errors = validateFields(formFields, user);
            if (errors.length) {
                setValidation(() => [...errors]);
                return;
            }
            setValidation([]);
        }
        const payload = {
            fee,
            city,
            email,
            domain,
            country,
            address,
            zip_code: zipCode,
            phone_number: phoneNumber,
            company_name: companyName,
            contact_person_name: contactPersonName,
            is_enabled: enabled ? !!+enabled.value : true,
        };
        if (updateClient) {
            updateClient(payload, setErrorMsg);
        }
    };
    const isFieldDisabled = (fieldName) => editableFields.findIndex(f => fieldName === f) === -1;
    const onFieldChange = (evt) => {
        const { value, name } = evt.target;
        if (name === 'domain') {
            setDomain(value);
        }
        if (name === 'companyName') {
            setCompanyName(value);
        }
        if (name === 'address') {
            setAddress(value);
        }
        if (name === 'zipCode') {
            setZipCode(value);
        }
        if (name === 'city') {
            setCity(value);
        }
        if (name === 'country') {
            setCountry(value);
        }
        if (name === 'contactPersonName') {
            setContactPersonName(value);
        }
        if (name === 'phoneNumber') {
            setPhoneNumber(value);
            if (value.length < 5) {
                setValidation((prev) => [...prev, 'phoneNumber']);
            }
            else {
                setShowPhoneErr(false);
                setValidation((prev) => [...(prev.filter(v => v !== 'phoneNumber'))]);
            }
        }
        if (name === 'email') {
            setEmail(value.trim());
            if (!regEx.test(String(value).toLowerCase())) {
                setValidation((prev) => [...prev, 'email']);
            }
            else {
                setValidation((prev) => [...(prev.filter(v => v !== 'email'))]);
                setShowEmailErr(false);
            }
        }
        if (name === 'iban') {
            setIban(value);
        }
        if (name === 'ibanEuro') {
            setIbanEuro(value);
        }
        if (name === 'ibanUSDollar') {
            setIbanUSDollar(value);
        }
        if (formFields) {
            formFields[name] = value;
        }
        setFormFields(Object.assign({}, formFields));
    };
    const handleFeeChange = (evt) => {
        if (isNaN(Number(evt.target.value))) {
            return;
        }
        setFee(evt.target.value);
        const fields = user === 'client' ? clientFields : customerFields;
        fields.fee = evt.target.value;
        setFormFields(Object.assign(Object.assign({}, formFields), fields));
    };
    const handleCurrencyChange = (event, newValue) => {
        if (newValue) {
            setCurrency(newValue);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.currency = newValue.value;
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
        else {
            setCurrency(null);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.currency = '';
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
    };
    const handleCustomerChange = (event, newValue) => {
        if (newValue) {
            setDefaultCustomer(newValue);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.defaultCustomer = (newValue.value === '1').toString();
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
        else {
            setDefaultCustomer(null);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.defaultCustomer = 'true';
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
    };
    const handleStatusChange = (event, newValue) => {
        if (newValue) {
            setEnabled(newValue);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.enabled = (newValue.value === '1').toString();
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
        else {
            setEnabled(null);
            const fields = user === 'client' ? clientFields : customerFields;
            fields.enabled = 'true';
            setFormFields(Object.assign(Object.assign({}, formFields), fields));
        }
    };
    const userName = user === 'client' ? 'Client' : 'Customer';
    return (React.createElement(Fragment, null,
        errorMsg ?
            React.createElement(Alert, { color: "warning" },
                React.createElement(React.Fragment, null, errorMsg)) : null,
        React.createElement(Box, { display: 'flex', gap: 2 },
            React.createElement(Box, { flex: '33%', display: 'flex', flexDirection: 'column', gap: 2 },
                React.createElement("div", null, "Company"),
                user === 'client' ? (React.createElement(FormControl, null,
                    React.createElement(TextField, { required: user === 'client', type: "text", size: "small", name: "domain", value: domain, onChange: onFieldChange, label: "Domain", disabled: !!clientDetails && isFieldDisabled('domain'), error: validation.includes('domain') && user === 'client', helperText: "Domain is required" }))) : null,
                React.createElement(FormControl, null,
                    React.createElement(TextField, { type: "text", size: "small", required: user === 'client', name: "companyName", value: companyName, label: "Name", onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('companyName'), error: validation.includes('companyName') && user === 'client', helperText: user === 'client' ? 'Name is required' : '' })),
                React.createElement(FormControl, null,
                    React.createElement(TextField, { type: "text", size: "small", label: "Address", required: user === 'client', name: "address", value: address, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('address'), error: validation.includes('address') && user === 'client', helperText: user === 'client' ? "Address is required" : '' })),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", required: user === 'client', label: "Zipcode", name: "zipCode", value: zipCode, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('zipCode'), error: validation.includes('zipCode') && user === 'client', helperText: user === 'client' ? 'Zipcode is required' : '' })),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", required: user === 'client', name: "city", label: "City", value: city, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('city'), error: validation.includes('city') && user === 'client', helperText: user === 'client' ? 'City is required' : '' })),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", name: "country", label: "Country", required: user === 'client', value: country, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('country'), error: validation.includes('country') && user === 'client', helperText: user === 'client' ? 'Country is required' : '' }))),
            React.createElement(Box, { flex: '33%', display: 'flex', flexDirection: 'column', gap: 2 },
                React.createElement("div", { className: "client_search_form_label" }, "Contact"),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", required: user === 'client', label: "Contact person name", name: "contactPersonName", value: contactPersonName, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('contactPersonName'), error: validation.includes('contactPersonName') && user === 'client', helperText: user === 'client' ? 'Contact person name is required' : '' })),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", required: user === 'client', label: "Phone number", name: "phoneNumber", value: phoneNumber, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('phoneNumber'), error: showPhonelErr, helperText: phoneErrText })),
                React.createElement(FormGroup, null,
                    React.createElement(TextField, { size: "small", type: "text", name: "email", label: "Contact email address", required: user === 'client', value: email, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('email'), error: showEmailErr, helperText: emailErrText }))),
            React.createElement(Box, { flex: '33%', display: 'flex', flexDirection: 'column', gap: 2 },
                React.createElement("div", { className: "client_search_form_label" }, "Settings"),
                React.createElement(Box, { display: 'flex', alignItems: 'center' },
                    React.createElement(FormGroup, { style: { width: '100%' } },
                        React.createElement(TextField, { type: "text", name: "iban", size: "small", required: user === 'client', label: (currency === null || currency === void 0 ? void 0 : currency.value) + ' ' + 'IBAN', value: iban, disabled: !!clientDetails && isFieldDisabled('iban'), onChange: onFieldChange, error: validation.includes('iban') && user === 'client', helperText: user === 'client' ? 'IBAN is required' : '', InputProps: {
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { size: 'small', edge: "end", "aria-label": "toggle password visibility" },
                                        React.createElement(Tooltip, { title: "IBAN is required for trading purposes only.\n                        If IBAN is not provided, trading cannot be enabled." },
                                            React.createElement(HelpIcon, null))))),
                            } }))),
                user === 'client' ? (React.createElement(React.Fragment, null,
                    React.createElement(FormGroup, null,
                        React.createElement(TextField, { type: "text", name: "fee", value: fee, required: true, size: "small", label: "Broker fee", onChange: handleFeeChange, disabled: !!clientDetails && isFieldDisabled('fee'), inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' }, error: validation.includes('fee') && user === 'client', helperText: "Broker fee is required" })),
                    React.createElement("div", { style: { marginBottom: '15px' } },
                        "Total fee: ",
                        Decimal.add(fee || 0, 25).toFixed()))) : null,
                user === 'client' && !clientDetails ? (React.createElement(FormGroup, null,
                    React.createElement(Autocomplete, { id: "customer", size: "small", value: defaultCustomer, options: defaultCustomerOptions, placeholder: "Select customer", onChange: handleCustomerChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Create default customer?", variant: "outlined" }))) }))) : null,
                user === 'customer' ? (React.createElement(FormGroup, null,
                    React.createElement(Autocomplete, { id: "status", size: "small", value: enabled, options: statusOptions, placeholder: "Select status", onChange: handleStatusChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: `${userName} status`, variant: "outlined" }))) }))) : null,
                React.createElement(FormGroup, null,
                    React.createElement(Autocomplete, { id: "currency", size: "small", value: currency, options: currencies, placeholder: "Select currency", onChange: handleCurrencyChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Default currency", variant: "outlined" }))) })),
                (currency === null || currency === void 0 ? void 0 : currency.value) !== 'EUR' ? (React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", label: "EURO IBAN", name: "ibanEuro", value: ibanEuro, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('ibanEuro') }))) : null,
                (currency === null || currency === void 0 ? void 0 : currency.value) !== 'USD' ? (React.createElement(FormGroup, null,
                    React.createElement(TextField, { type: "text", size: "small", label: "US Dollar IBAN", name: "ibanUSDollar", value: ibanUSDollar, onChange: onFieldChange, disabled: !!clientDetails && isFieldDisabled('ibanUSDollar') }))) : null)),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
            React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: clientDetails ? handleUpdate : handleCreate, style: { marginRight: '10px' } },
                clientDetails ? 'Save' : 'Create',
                " ",
                userName),
            React.createElement(Button, { size: "small", variant: "outlined", onClick: toggleFunc }, "Close"))));
};
export default WithdrawalForm;
