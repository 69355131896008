import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import DLTAutocomplete from '../form/DLTAutocomplete';
import { DatePicker } from '@mui/x-date-pickers';
import DLTNumberInput from '../form/DLTNumberInput';
const DLTFTableFilters = (props) => {
    const { control, getValues, reset, resetField } = useForm({
        mode: 'onChange',
    });
    if (!props.fields.length) {
        return null;
    }
    const renderInput = (input) => {
        switch (input.type) {
            case 'text':
                return (React.createElement(Box, { key: input.name, sx: { display: 'flex', minWidth: 180 } },
                    React.createElement(DLTAutocomplete, { fullWidth: true, label: input.label, name: input.name, options: input.values || [], control: control })));
            case 'number':
                return (React.createElement(Box, { key: input.name, sx: { display: 'flex', minWidth: 180 } },
                    React.createElement(DLTNumberInput, { fullWidth: true, label: input.label, name: input.name, control: control })));
            case 'dateFrom':
            case 'dateTo':
                return (React.createElement(Box, { key: input.name, sx: { display: 'flex', minWidth: 180 } },
                    React.createElement(Controller, { name: input.name, control: control, defaultValue: null, render: ({ field }) => (React.createElement(DatePicker, Object.assign({}, field, { format: 'dd-MM-yyyy', label: input.label, disableFuture: true, slotProps: {
                                textField: { size: 'small', sx: { width: '200px' } },
                                field: { clearable: true },
                            } }))) })));
            default:
                return (React.createElement(Box, { key: input.name, sx: { display: 'flex', minWidth: 180 } },
                    React.createElement(DLTAutocomplete, { fullWidth: true, multi: input.multi, label: input.label, name: input.name, options: input.values || [], control: control })));
        }
    };
    return (React.createElement(Box, { sx: { display: 'flex', width: '100%', flexWrap: 'wrap' }, gap: 2, pb: 2 },
        React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, gap: 2 }, props.fields.map((input) => renderInput(input))),
        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 2 },
            React.createElement(Button, { onClick: () => props.onChange(getValues()), variant: 'contained', size: 'small' }, "Apply Filters"),
            React.createElement(Button, { onClick: () => {
                    props.onReset();
                    reset();
                    props.onChange(getValues());
                }, variant: 'outlined', size: 'small' }, "Clear Filters"))));
};
export default DLTFTableFilters;
