import React, { useState, useEffect, useCallback, } from 'react';
import Decimal from 'decimal.js';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import SubItemService from '../../../../../services/subItemService';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import showNotification from '../../../../../shared/helpers/notifications';
import { addNewSubOrder } from '../../../../../redux/actions/subOrdersActions';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { PARTIAL_ORDER_CLOSE_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
import { getAvailableExchanges } from '../../../../../redux/selectors/selectors';
import { SUBORDERS_ORDER_EXECUTE_FAIL } from '../../../../../redux/actionTypes/subOrdersActionTypes';
import { Box, Button, MenuItem, TextField } from '@mui/material';
const splitOrderFinalStatuses = ['Filled', 'Canceled', 'ToCancel', 'CanceledPartiallyFilled'];
/* eslint-disable camelcase */
const AddNewSuborder = ({ base, quote, action, code, remainder, features, status, orderAmount, exchanges, toggleCloseModal, masterTenant, disabledClose, customerCode, }) => {
    const [amount, setAmount] = useState('0');
    const [type, setType] = useState({ value: 'Market', label: 'Market' });
    const [subOrderExchange, setSubOrderExchange] = useState(exchanges[0]);
    const [limitPrice, setLimitPrice] = useState('0');
    const [loading, setLoading] = useState(false);
    const [cancelSplitOrderDisabled, setDisabledCancel] = useState(false);
    const tradingPair = `${base}/${quote}`;
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const getSuborderPayload = () => ({
        customer_code: customerCode,
        exchange_code: subOrderExchange ? subOrderExchange.value : '',
        tradingPair,
        action,
        type: type ? type.value : '',
        base,
        quote,
        amount,
        limit_price: limitPrice,
    });
    const validateStatuses = () => {
        if (splitOrderFinalStatuses.includes(status)) {
            setDisabledCancel(true);
        }
        else {
            setDisabledCancel(false);
        }
    };
    useEffect(() => {
        validateStatuses();
    }, []);
    useEffect(() => {
        if (!splitOrderFinalStatuses.includes(status)) {
            setDisabledCancel(disabledClose);
        }
    }, [disabledClose]);
    const validateAddOrder = (value) => {
        if (cancelSplitOrderDisabled) {
            return false;
        }
        if (!value) {
            return false;
        }
        const amountD = new Decimal(value);
        if (amountD.isNaN()) {
            return false;
        }
        if (amountD.isZero()) {
            return false;
        }
        if (amountD.lessThan(0)) {
            return false;
        }
        if (amountD.greaterThan(orderAmount)) {
            return false;
        }
        if (amountD.greaterThan(remainder)) {
            return false;
        }
        if (type && type.value === 'Limit') {
            if (!limitPrice) {
                return false;
            }
            if (new Decimal(limitPrice).lessThanOrEqualTo(0)) {
                return false;
            }
        }
        if (subOrderExchange && Object.keys(subOrderExchange).length === 0 && subOrderExchange.constructor === Object) {
            return false;
        }
        if (masterTenant) {
            return false;
        }
        return true;
    };
    const addNewOrder = () => {
        // TODO: fields validation
        const payload = getSuborderPayload();
        setAmount('0');
        setLimitPrice('0');
        setLoading(true);
        const service = new SubItemService({
            url: `/orders/partials/${code}/suborders`,
            method: 'post',
            data: payload,
        });
        service.makeRequest()
            .then((response) => {
            setLoading(false);
            dispatch(addNewSubOrder(response));
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.POST_NEW_SUB_ORDER);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            dispatch({ type: SUBORDERS_ORDER_EXECUTE_FAIL, code, message });
            setLoading(false);
        });
    };
    const closeOrder = () => {
        // todo: use proper validations hooks
        if (cancelSplitOrderDisabled) {
            return;
        }
        dispatch({ type: PARTIAL_ORDER_CLOSE_REQUEST, code });
        toggleCloseModal();
    };
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSubOrderExchange({ value: newValue, label: newValue.toLowerCase() });
    };
    const onTypeChange = (event) => {
        const newValue = event.target.value;
        setType({ value: newValue, label: newValue });
    };
    /* eslint-disable no-restricted-globals */
    const onAmountChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value))) {
            return;
        }
        setAmount(value);
    };
    const onLimitPriceChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value))) {
            return;
        }
        setLimitPrice(value);
    };
    useEffect(() => {
        if (subOrderExchange && subOrderExchange.value === 'cex') {
            setType({ value: 'Limit', label: 'Limit' });
        }
    }, [subOrderExchange]);
    const options = subOrderExchange && subOrderExchange.value === 'cex' ? [
        { value: 'Limit', label: 'Limit' },
    ] : [
        { value: 'Market', label: 'Market' },
        { value: 'Limit', label: 'Limit' },
    ];
    return (React.createElement(Box, { style: { display: 'flex', flexDirection: 'column' }, gap: 1 },
        React.createElement(Box, { style: { display: 'flex' }, gap: 1 },
            React.createElement(TextField, { fullWidth: true, id: "amount", value: amount, required: true, label: "Amount", variant: "outlined", size: "small", placeholder: "0", onChange: onAmountChange, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' }, InputProps: { endAdornment: base } }),
            type && type.value === 'Limit'
                && (React.createElement(TextField, { required: true, fullWidth: true, type: "text", variant: "outlined", size: "small", placeholder: "0", label: "Limit", value: limitPrice, onChange: onLimitPriceChange, InputProps: { endAdornment: quote }, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } })),
            React.createElement(TextField, { select: true, fullWidth: true, name: "selectMarket", value: type.value, label: "Type", size: "small", variant: "outlined", required: true, onChange: onTypeChange }, options.map(option => React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))),
            React.createElement(TextField, { select: true, fullWidth: true, size: "small", label: "Exchange", variant: "outlined", name: "selectExchange", required: true, value: subOrderExchange === null || subOrderExchange === void 0 ? void 0 : subOrderExchange.value, onChange: handleSelectChange }, exchanges.map(option => React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))),
        React.createElement(Box, { style: { display: 'flex' }, justifyContent: 'flex-end', gap: 1 },
            React.createElement(Permissions, { features: features, permissions: ['order.create'] },
                React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: addNewOrder, disabled: !validateAddOrder(amount) }, !loading ? 'Add Suborder' : 'Posting...')),
            React.createElement(Permissions, { features: features, permissions: ['order.update'] },
                React.createElement(Button, { size: "small", color: "error", variant: "contained", onClick: closeOrder, className: "icon modal_ok square", disabled: cancelSplitOrderDisabled }, "Cancel order")))));
};
const stateToProps = (state) => {
    const { features, masterTenant } = state.client;
    const { loadingMarkets } = state.placeOrder;
    let exchanges = [];
    if (!loadingMarkets) {
        exchanges = getAvailableExchanges(state);
    }
    return Object.assign(Object.assign({}, state), { features,
        exchanges,
        masterTenant });
};
export default connect(stateToProps)(AddNewSuborder);
