/* eslint-disable */
class OperationalStatusManager {
    constructor() {
        this.events = {};
        this.setOperationalStatus = (status) => {
            this.operationalStatus = status;
        };
        this.geOperationalStatus = () => this.operationalStatus;
        this.setDateFrom = () => {
            this.dateFrom = new Date();
        };
        this.checkOperational = () => {
            if (!this.dateFrom) {
                this.setOperationalStatus(false);
                this.emitStatusUpdate();
                return;
            }
            const current = new Date();
            const timeSpan = current.getTime() - this.dateFrom.getTime();
            if (timeSpan > 10000) {
                this.setOperationalStatus(false);
                this.emitStatusUpdate();
            }
            else {
                this.setOperationalStatus(true);
                this.emitStatusUpdate();
            }
        };
        this.registerStatusUpdateListener = (callback) => {
            if (!this.events.statusUpdate) {
                this.events.statusUpdate = [];
            }
            this.events.statusUpdate.push(callback);
        };
        this.emitStatusUpdate = () => {
            if (this.events.statusUpdate && this.events.statusUpdate.length) {
                this.events.statusUpdate.forEach((callback) => callback(this.operationalStatus));
            }
        };
        this.clearStatusManager = () => {
            clearInterval(this.timer);
        };
        this.timer = setInterval(() => {
            this.checkOperational();
        }, 10000);
    }
}
export default class WebSocketProvider {
    constructor(wsurl) {
        // public props
        this.url = '';
        this.connectionStatus = 'Open';
        this.subscribers = {};
        this.events = {};
        this.socket = {};
        this.timer = null;
        this.tries = 0;
        this.maxTries = 4;
        this.manager = null;
        this.availableChannels = [];
        this.init = () => {
            this.socket = new WebSocket(this.url);
            this.socket.onopen = () => {
                this.setConnectionStatus('Open');
                // send all existing subscribers
                if (this.tries > 0) {
                    this.authenticateWS();
                    this.sendAllSubscribers();
                }
            };
            this.socket.onmessage = (message) => {
                const data = JSON.parse(message.data);
                if (data &&
                    data.channel === 'system' &&
                    data.payload &&
                    data.payload.available_channels &&
                    data.payload.available_channels.length) {
                    this.setWSAuthenticated(true);
                    this.availableChannels = data.payload.available_channels;
                }
                if (this.wsAuthenticated) {
                    try {
                        this.emitSubscription(data);
                        this.emitWSListener(data);
                    }
                    catch (error) {
                        throw new Error(`Something went wrong in subscription - ${error}`);
                    }
                }
                this.manager.setDateFrom();
            };
            this.socket.onclose = (event) => {
                console.log(`[close] Connection closed, code=${event.code} reason=${event.reason}`);
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                // TODO: can add max repeat try to connect logic here
                this.reconnect();
                this.manager.clearStatusManager();
                this.setConnectionStatus('Closed');
            };
            this.socket.onerror = () => {
                // TODO notify error to the client
                this.setConnectionStatus('Closed');
            };
        };
        this.getAvailableChannels = () => this.availableChannels;
        this.authenticateWS = () => {
            const token = window.localStorage.getItem('wsToken');
            if (!token) {
                return;
            }
            const authMessage = {
                event: 'authenticate',
                auth: {
                    type: 'jwt',
                    token,
                },
            };
            if (this.socket.readyState === 1) {
                this.sendMessage(JSON.stringify(authMessage));
            }
            else {
                this.socket.addEventListener('open', () => {
                    this.sendMessage(JSON.stringify(authMessage));
                });
            }
        };
        this.setWSAuthenticated = (isAuthenticated) => {
            this.wsAuthenticated = isAuthenticated;
        };
        this.sendMessage = (message) => {
            if (this.socket.readyState === 1) {
                this.socket.send(message);
            }
        };
        this.sendAllSubscribers = () => {
            const channels = Object.keys(this.subscribers);
            const payload = {
                event: 'subscribe',
                channels,
            };
            if (channels.length) {
                this.sendMessage(JSON.stringify(payload));
            }
        };
        this.addSubscription = (channel, subscriber) => {
            if (!this.subscribers[channel]) {
                this.subscribers[channel] = [];
                const payload = {
                    event: 'subscribe',
                    channels: [channel],
                };
                this.sendMessage(JSON.stringify(payload));
            }
            this.subscribers[channel].push(subscriber);
        };
        this.removeSubscription = (channel, componentId) => {
            if (!this.subscribers[channel]) {
                return;
            }
            const filterListeners = (listener) => listener.componentId !== componentId;
            this.subscribers[channel] = this.subscribers[channel].filter(filterListeners);
            if (this.subscribers[channel].length === 0) {
                const payload = {
                    event: 'unsubscribe',
                    channels: [channel],
                };
                this.sendMessage(JSON.stringify(payload));
                delete this.subscribers[channel];
            }
        };
        this.emitSubscription = (data) => {
            const { channel } = data;
            // TODO need to handle all events!!!!!!!!!!!!!!!!!
            if (!this.subscribers[channel]) {
                return;
            }
            const fireCallbacks = (subscriber) => {
                subscriber.callback(data);
            };
            const l = this.subscribers[channel].length;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < l; i++) {
                const subscriber = this.subscribers[channel][i];
                fireCallbacks(subscriber);
            }
        };
        // not require sending subscribe
        this.registerWSListener = (channel, callback) => {
            if (!this.events[channel]) {
                this.events[channel] = [];
            }
            this.events[channel].push(callback);
        };
        this.emitWSListener = (data) => {
            const { channel } = data;
            if (!this.events[channel]) {
                return;
            }
            const fireCallbacks = (subscriber) => {
                subscriber.callback(data);
            };
            const l = this.events[channel].length;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < l; i++) {
                const subscriber = this.events[channel][i];
                fireCallbacks(subscriber);
            }
        };
        this.registerConnectionStatusListener = (callback) => {
            if (!this.events.connectionStatus) {
                this.events.connectionStatus = [];
            }
            this.events.connectionStatus.push(callback);
            this.emitConnectionStatusChange();
        };
        this.setConnectionStatus = (status) => {
            this.connectionStatus = status;
            this.emitConnectionStatusChange();
        };
        this.emitConnectionStatusChange = () => {
            if (this.events.connectionStatus && this.events.connectionStatus.length) {
                this.events.connectionStatus.forEach((callback) => callback(this.connectionStatus));
            }
        };
        this.isWsAuthenticated = () => this.wsAuthenticated;
        this.getOperationalStatusManager = () => this.manager;
        this.timerCalc = (tries) => [1000, 2000, 5000, 10000][tries - 1] || 10000;
        this.reconnect = () => {
            this.closeConnection();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.tries += 1;
                this.wsAuthenticated = false;
                this.init(this.url);
            }, this.timerCalc(this.tries + 1));
        };
        this.closeConnection = () => {
            this.socket.close();
        };
        this.wsAuthenticated = false;
        this.url = wsurl;
        this.manager = new OperationalStatusManager();
        this.init(wsurl);
    }
}
