import React, { useEffect, useRef, useState } from 'react';
import DLTTableHeadComponent from './DLTTableHeadComponent';
import DLTTableBodyComponent from './DLTTableBodyComponent';
import { getComparator, stableSort } from '../../helpers/tables';
import { Paper, Table, TableContainer, TablePagination } from '@mui/material';
/* eslint-disable max-len */
/**
 * @param {DLTTableCol[]} columns - Definition of the table columns
 * @param {boolean} [squareCorners=false] - Table corners style
 * @param {boolean} [skipFirstSorting=false] - Used to show the data as is, with further sorting
 */
/* eslint-enable max-len */
const DLTFTable = (props) => {
    const { rowsPerPage = 10, page = 0, defaultOrder = 'desc', defaultOrderProp = 'id', total, internalFilter = true, internalSorting = true, skipFirstSorting = false, pagination = false, squareCorners = false, noHead = false, onQueryParamsChange, onCellEdit, onRowClick, } = props;
    const [state, setState] = useState({
        order: defaultOrder,
        orderBy: defaultOrderProp,
        rowsPerPage: rowsPerPage,
        page: page,
        skipFirstSorting,
    });
    const isMountRef = useRef(true);
    useEffect(() => {
        if (!isMountRef.current) {
            if (onQueryParamsChange) {
                onQueryParamsChange(state);
            }
        }
    }, [state]);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    useEffect(() => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0 })));
    }, [total]);
    const handleSort = (order, orderBy) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { order,
            orderBy, skipFirstSorting: false })));
    };
    const handleChangePage = (event, newPage) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: newPage })));
    };
    const handleChangeRowsPerPage = (event) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { rowsPerPage: parseInt(event.target.value, 10), page: 0 })));
    };
    const handleCellEdit = (id, entity, value) => {
        if (onCellEdit) {
            onCellEdit(id, entity, value);
        }
    };
    const handleRowClick = (id) => {
        if (onRowClick) {
            onRowClick(id);
        }
    };
    const visibleRows = React.useMemo(() => {
        if (!internalSorting)
            return;
        const rowsPerPage = state.rowsPerPage === 'all' ? total : state.rowsPerPage;
        return stableSort(props.data, getComparator(state.order, state.orderBy)).slice(state.page * rowsPerPage, state.page * rowsPerPage + rowsPerPage);
    }, [state.order, state.orderBy, state.page, state.rowsPerPage, props.data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, { component: Paper, square: squareCorners },
            React.createElement(Table, null,
                noHead ? null : (React.createElement(DLTTableHeadComponent, { columns: props.columns, onRequestSort: handleSort, order: state.order, orderBy: state.orderBy })),
                React.createElement(DLTTableBodyComponent, { columns: props.columns, rows: internalSorting && !state.skipFirstSorting ? visibleRows : props.data, onCellEdit: handleCellEdit, onRowClick: onRowClick ? handleRowClick : undefined }))),
        pagination ? (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25], rowsPerPage: state.rowsPerPage === 'all' ? total : state.rowsPerPage, count: total, component: 'div', page: state.page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage })) : null));
};
export default DLTFTable;
