import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, InputAdornment, Tab, Tabs, TextField, Typography, } from '@mui/material';
import BalancesContainer from './BalancesContainer';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
import TradeWithdrawal, { TransferFunds } from './BalancesActions';
import { useBalances } from '../../../../Balances/BalancesProvider';
import { getCustodyCurrencies } from '../../../../../redux/selectors/currenciesSelectors';
import SearchIcon from '@mui/icons-material/Search';
const Balances = ({ features, widgetKey, clientInfo, selectedClient, selectedCustomer, customer_code, isCustomer, currencies, }) => {
    const [hideZero, setDisplayZero] = useState(true);
    const [customerCode, setCustomerCode] = useState('');
    const [clientCode, setClientCode] = useState('');
    const [balances, setBalances] = useState([]);
    const [dialogContent, setDialogContent] = useState();
    const [dialogTitle, setDialogTitle] = useState('');
    const [isActionDialogOpened, setIsActionDialogOpened] = useState(false);
    const [searchString, setSerachString] = useState('');
    const { data, activeTab, setActiveTab } = useBalances();
    const memoBalances = useMemo(() => (data ? data : []), [data]);
    const clientBalancesActions = [
        { id: 'withdrawal', label: 'Create withdrawal', action: (id) => handleWithdrawal(id) },
    ];
    const customerBalancesActions = [
        { id: 'withdrawal', label: 'Create withdrawal', action: (id) => handleWithdrawal(id) },
    ];
    const customerAccountsActions = [
        { id: 'transfer', label: 'Transfer funds', action: (id) => handleTransfer(id) },
    ];
    const handleWithdrawal = (id) => {
        const balance = balances.find((b) => b.code === id);
        if (balance) {
            const dialogComponent = React.createElement(TradeWithdrawal, {
                item: balance,
                path: '',
                toggle: () => setIsActionDialogOpened(false),
                clientCode,
            });
            setDialogContent(dialogComponent);
            setDialogTitle(`${balance.code} withdrawal`);
        }
        setIsActionDialogOpened(!isActionDialogOpened);
    };
    const handleTransfer = (accountId) => {
        var _a;
        let balance;
        for (const b of balances) {
            const searchBalance = (_a = b === null || b === void 0 ? void 0 : b.accounts) === null || _a === void 0 ? void 0 : _a.find((a) => a.code === accountId);
            if (searchBalance) {
                balance = b;
            }
        }
        if (balance) {
            setDialogTitle(`${balance.code} transfer`);
            const dialogComponent = React.createElement(TransferFunds, {
                walletCode: accountId,
                clientCode,
                customerCode,
                item: balance,
                toggle: () => setIsActionDialogOpened(false),
            });
            setDialogContent(dialogComponent);
        }
        setIsActionDialogOpened(!isActionDialogOpened);
    };
    const handleCustomerDataChange = (customer, featureArr) => {
        const code = customer ? customer.code : customer_code ? customer_code : '';
        if (featureArr && Array.isArray(featureArr) && featureArr.length && featureArr.includes('balance.read')) {
            setCustomerCode(code);
        }
    };
    const handleClientDataChange = (option) => {
        const code = option ? (option === null || option === void 0 ? void 0 : option.code) || (option === null || option === void 0 ? void 0 : option.value) : '';
        setClientCode(code);
    };
    const toggleZeroBalances = (evt) => {
        const { checked } = evt.target;
        setDisplayZero(checked);
    };
    const checkboxId = `${widgetKey}showZeroBalances`;
    useEffect(() => {
        handleCustomerDataChange(selectedCustomer, features);
    }, [selectedCustomer, features, customer_code]);
    useEffect(() => {
        const clientData = selectedClient || clientInfo;
        handleClientDataChange(clientData);
    }, [clientInfo, selectedClient]);
    useEffect(() => {
        if (customer_code || customerCode) {
            setActiveTab(1);
        }
        else {
            setActiveTab(0);
        }
    }, [customerCode]);
    useEffect(() => {
        if (memoBalances) {
            setBalances(filterBalances(memoBalances, searchString));
        }
        else {
            setBalances([]);
        }
    }, [data, searchString]);
    const filterBalances = (balances, searchString) => {
        return balances
            .filter((b) => b.code.includes(searchString.toUpperCase()));
    };
    const handleTabChange = (event, tab) => {
        setActiveTab(tab);
    };
    if (!currencies || currencies.length === 0) {
        return;
    }
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, { p: 2 },
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Typography, { variant: 'h6' }, "Balances"),
                React.createElement(FormControlLabel, { label: 'Hide zero balances', control: React.createElement(Checkbox, { checked: hideZero, name: checkboxId, onChange: toggleZeroBalances }) })),
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                isCustomer ? null : (React.createElement(Box, { mb: 2 },
                    React.createElement(Tabs, { value: activeTab, onChange: handleTabChange },
                        React.createElement(Tab, { label: 'Client' }),
                        React.createElement(Tab, { label: 'Customer', disabled: !selectedCustomer })))),
                React.createElement(TextField, { placeholder: 'Search', value: searchString, onChange: (e) => setSerachString(e.target.value), size: 'small', InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                            React.createElement(SearchIcon, null))),
                    } })),
            isCustomer ? (React.createElement(BalancesContainer, { actions: customerBalancesActions, accountActions: customerAccountsActions, balances: balances, currencies: currencies, hideZero: hideZero, client: selectedClient, clientInfo: clientInfo })) : (React.createElement(React.Fragment, null,
                currencies && activeTab === 0 ? (React.createElement(BalancesContainer, { actions: clientBalancesActions, accountActions: [], balances: balances, currencies: currencies, hideZero: hideZero, client: selectedClient, clientInfo: clientInfo })) : null,
                currencies && activeTab === 1 ? (React.createElement(BalancesContainer, { actions: customerBalancesActions, accountActions: customerAccountsActions, balances: balances, currencies: currencies, hideZero: hideZero, client: selectedClient, clientInfo: clientInfo })) : null))),
        React.createElement(Dialog, { maxWidth: 'sm', fullWidth: true, open: isActionDialogOpened, onClose: () => setIsActionDialogOpened(!isActionDialogOpened) },
            React.createElement(DialogTitle, null, dialogTitle),
            React.createElement(DialogContent, null, dialogContent))));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    const selectedClient = getSelectedClientInputValue(state);
    const { selectedCustomer } = state.customersSearch;
    const currencies = getCustodyCurrencies(state);
    const { clientInfo, customerInfo, features, customer_code, masterTenant } = state.client;
    return {
        features,
        widgetKey,
        clientInfo,
        selectedClient,
        customerInfo,
        customer_code,
        selectedCustomer,
        masterTenant,
        isCustomer: !!customer_code,
        currencies,
    };
};
export default connect(stateToProps)(React.memo(Balances));
