var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, LinearProgress, Tab, TablePagination, Tabs, TextField, Typography, useTheme, } from '@mui/material';
import axios from 'axios';
import Decimal from 'decimal.js';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { groupBy } from 'lodash';
import SettlementsTableBody from './SettlementsTableBody';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ConfirmationDialog from '../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import useConfirmationDialog from '../../../../../shared/components/confirmationDialog/useConfirmationDialog';
import InitiateSettlements from './InitiateSettlements';
import { ASSETS } from './ClientSettlements/assets';
import { getCustodyCurrencies } from '../../../../../redux/selectors/currenciesSelectors';
function mapSettlements(settlements, customers, clients) {
    return settlements.map((item) => {
        var _a, _b, _c, _d;
        let command = item.commands.records.find((record) => record.type === 'pool_wallet_to_client' || record.type === 'client_to_pool_wallet');
        if (!command) {
            command = item.commands.records[0];
        }
        const clientCode = ((_a = customers.find((c) => c.code === item.customer_code)) === null || _a === void 0 ? void 0 : _a.client_code) || 'N/A';
        return {
            settlementCode: item.settlement_code,
            clientCode: clientCode,
            clientName: ((_b = clients.find((c) => c.code === clientCode)) === null || _b === void 0 ? void 0 : _b.company_name) || clientCode,
            customerName: ((_c = customers.find((c) => c.code === item.customer_code)) === null || _c === void 0 ? void 0 : _c.company_name) || item.customer_code || 'N/A',
            customerCode: item.customer_code || 'N/A',
            currency: item.currency_code,
            amountToSettle: (command === null || command === void 0 ? void 0 : command.amount) || 'N/A',
            direction: (command === null || command === void 0 ? void 0 : command.type) === 'pool_wallet_to_client' ? 'to client' : 'from client',
            status: item.state,
            failedReason: item.failed_reason || '',
            poolWalletBalance: item.wallet === undefined ? 'N/A' : item.wallet.balance,
            poolWalletCode: (_d = item.wallet) === null || _d === void 0 ? void 0 : _d.code,
            commands: item.commands,
            created_at: item.created_at,
        };
    });
}
const SettlementsTable = (props) => {
    const [filterSmall, setFilterSmall] = useState(true);
    const [initiateDialog, setInitiateDialog] = useState(false);
    const [declineDialog, setDeclineDialog] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [groupedSettlements, setGroupedSettlements] = useState([]);
    const [settlements, setSettlements] = useState([]);
    const [statusFilter, setStatusFilter] = useState('created');
    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState(null);
    const [settlemntsToExecute, setSettlemntsToExecute] = useState(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const { isConfirmOpen, openConfirmDialog, closeConfirmDialog } = useConfirmationDialog();
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('settlements/accounts', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                states: statusFilter,
                client_code: props.selectedClient,
                limit: statusFilter !== 'created' ? rowsPerPage : null,
                page: statusFilter !== 'created' ? page + 1 : null,
                sort_by: statusFilter !== 'created' ? orderBy : null,
                sort_direction: statusFilter !== 'created' ? order : null,
                currency: currency === null || currency === void 0 ? void 0 : currency.ticker.toLowerCase(),
                type: 'account',
            },
        })
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            const filterAmounts = (records) => {
                return records.filter((settlement) => {
                    return settlement.commands.records.some((r) => new Decimal(r.usd_amount).greaterThan(new Decimal('0.1')));
                });
            };
            const filtered = filterSmall ? filterAmounts(response.data.records) : response.data.records;
            setTotalItems(filtered.length || 0);
            const visited = {};
            for (const settlement of filtered) {
                if (visited[settlement.currency_code] !== undefined) {
                    settlement.wallet = yield visited[settlement.currency_code];
                }
                else {
                    visited[settlement.currency_code] = fetchWallets(settlement.currency_code)
                        .then((wallet) => {
                        var _a, _b;
                        const balance = (_b = (_a = wallet === null || wallet === void 0 ? void 0 : wallet.balance) === null || _a === void 0 ? void 0 : _a.available) !== null && _b !== void 0 ? _b : '0';
                        const walletRecord = !wallet ? undefined : { balance, code: wallet.code };
                        return walletRecord;
                    })
                        .catch(() => {
                        visited[settlement.currency_code] = undefined;
                        return undefined;
                    });
                    settlement.wallet = yield visited[settlement.currency_code];
                }
            }
            return filtered;
        }))
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const fetchWallets = (currency_code) => __awaiter(void 0, void 0, void 0, function* () {
        if (!props.clientId) {
            return;
        }
        const internalCode = ASSETS[currency_code.toUpperCase()];
        const currency = internalCode !== undefined ? internalCode : currency_code;
        return yield axios
            .get(`/custody/${props.clientId}/wallets?limit=1000&state=all&currency_code=${currency}`)
            .then((res) => {
            const result = res.data.records.find((d) => d.type === 'pool');
            return result;
        });
    });
    const fetchCustomer = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios.get(`customers/${id}`, {
            params: {
                details: true,
            },
        });
    });
    const executeSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/execute/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_EXECUTE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const declineSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/decline/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const refreshBalance = (code) => {
        return axios
            .post(`/custody/wallets/${code}/balance`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then(() => {
            showNotification({
                message: `Balance has been successfully updated`,
                color: 'success',
            });
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, 'Failed to refresh the balance');
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const executeSettlements = (arr) => __awaiter(void 0, void 0, void 0, function* () {
        const ids = arr.map((item) => item.settlementCode);
        if (ids.length) {
            let n = 0;
            while (n < arr.length) {
                const id = ids[n];
                yield executeSettlement(id);
                n += 1;
            }
        }
    });
    const onExecuteSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onDeclineSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was declined`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onExecuteAll = (settlements) => __awaiter(void 0, void 0, void 0, function* () {
        if (settlements) {
            yield executeSettlements(settlements).finally(() => {
                getData();
            });
            showNotification({
                message: `All settlements were executed`,
                color: 'success',
                dispatch: errorNotice,
            });
        }
    });
    const declineAll = () => {
        return axios.post('/settlements/accounts/decline_all', { settlement_type: 'account' }, {
            baseURL: getEnvSettings().adminApiUrl,
        });
    };
    const handleDeclineAll = () => {
        setDeclineDialog(false);
        declineAll()
            .then(() => {
            showNotification({
                message: `All settlements were declined`,
                color: 'success',
                dispatch: errorNotice,
            });
            getData();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, 'Failed to decline settlements');
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        });
    };
    const handleCollapse = (index) => {
        const updated = [...groupedSettlements];
        updated[index].collapsed = !updated[index].collapsed;
        setGroupedSettlements(updated);
    };
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let settlements = [];
        const customers = [];
        setLoading(true);
        yield fetchData().then((res) => {
            settlements = res ? res.filter((s) => s.customer_code) : [];
        });
        if (settlements.length) {
            let n = 0;
            const visited = [];
            if (props.selectedClient === null) {
                while (n < settlements.length) {
                    const settlement = settlements[n];
                    if (settlement.customer_code && visited.findIndex((v) => v === settlement.customer_code) === -1) {
                        yield fetchCustomer(settlement.customer_code)
                            .then((res) => {
                            customers.push(res.data);
                            if (settlement.customer_code) {
                                visited.push(settlement.customer_code);
                            }
                        })
                            .catch((e) => {
                            if (e.message === 'canceled') {
                                return;
                            }
                            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
                            showNotification({
                                message: `Error: ${message}`,
                                color: 'error',
                                dispatch: errorNotice,
                            });
                        });
                    }
                    n += 1;
                }
            }
        }
        if (statusFilter === 'created') {
            const grouped = Object.entries(groupBy(mapSettlements(settlements, [...props.customers, ...customers], props.clients), 'customerName'))
                .map((group) => ({
                name: group[1][0].customerName,
                customerCode: group[1][0].customerCode,
                settlements: group[1],
                collapsed: true,
            }))
                .sort((a, b) => { var _a; return (_a = a === null || a === void 0 ? void 0 : a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.name); });
            setGroupedSettlements(grouped);
        }
        else {
            setSettlements(mapSettlements(settlements, [...props.customers, ...customers], props.clients));
        }
        setLoading(false);
        return { settlements };
    });
    useEffect(() => {
        if (props.customersLoaded && !props.clientsLoading) {
            getData();
        }
    }, [
        statusFilter,
        page,
        rowsPerPage,
        props.selectedClient,
        props.customersLoaded,
        currency,
        order,
        orderBy,
        filterSmall,
    ]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCurrencyChange = (value) => {
        setCurrency(value);
        setPage(0);
    };
    const visibleRows = React.useMemo(() => settlements, [page, rowsPerPage, settlements]);
    const onFilterChange = (filter) => {
        setPage(0);
        setStatusFilter(filter);
    };
    const handleToggleDialog = (result) => {
        setInitiateDialog(false);
        if (result) {
            getData();
            setPage(0);
            setStatusFilter('created');
        }
    };
    const handleSort = (order, orderBy) => {
        setOrder(order);
        setOrderBy(orderBy);
    };
    const handleRefreshBalance = (walletCode) => {
        refreshBalance(walletCode);
    };
    return (React.createElement(Box, { sx: { width: '100%' }, p: 0.6 },
        React.createElement(Box, null,
            React.createElement(Box, { height: '5px' }, loading && React.createElement(LinearProgress, null)),
            React.createElement(Box, { mb: 2, sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `2px solid ${theme.palette.background.default}`,
                } },
                React.createElement(Tabs, { value: statusFilter, onChange: (e, value) => onFilterChange(value), "aria-label": 'basic tabs example' },
                    React.createElement(Tab, { label: 'Created', value: 'created', disabled: loading }),
                    React.createElement(Tab, { label: 'Pending', value: 'pending', disabled: loading }),
                    React.createElement(Tab, { label: 'Failed', value: 'failed', disabled: loading }),
                    React.createElement(Tab, { label: 'Declined', value: 'declined', disabled: loading }),
                    React.createElement(Tab, { label: 'Success', value: 'success', disabled: loading })),
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', gap: 2, flex: '1 0 auto', justifyContent: 'center' } },
                    React.createElement(Box, { sx: { flex: '1 0 auto', maxWidth: '25ch' } },
                        React.createElement(Autocomplete, { loading: false, options: props.custodyCurrencies, value: currency, size: 'small', getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => (React.createElement(TextField, Object.assign({ name: 'currency_code' }, params, { label: 'Currency', variant: 'outlined', value: {
                                    value: currency,
                                    label: 'Currency',
                                }, size: 'small' }))) })),
                    React.createElement(Box, null,
                        React.createElement(FormGroup, null,
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { value: filterSmall, defaultChecked: true, onChange: () => setFilterSmall(!filterSmall) }), label: 'Filter Small' })))),
                statusFilter !== 'created' ? null : (React.createElement(Box, { sx: { display: 'flex', gap: 1 } },
                    React.createElement(Button, { size: 'small', variant: 'outlined', onClick: () => getData() }, "Refresh"),
                    React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => setInitiateDialog(true) }, "Initiate"),
                    React.createElement(Button, { size: 'small', variant: 'contained', color: 'error', onClick: () => setDeclineDialog(true) }, "Decline all"))),
                React.createElement(Dialog, { open: initiateDialog, onClose: () => setInitiateDialog(false), fullWidth: true },
                    React.createElement(InitiateSettlements, { toggleDialog: (e) => handleToggleDialog(e), settlementType: 'account' })),
                React.createElement(Dialog, { open: declineDialog, onClose: () => setDeclineDialog(false), fullWidth: true },
                    React.createElement(DialogTitle, null, "Decline All Settlements"),
                    React.createElement(DialogContent, null, "Are you sure to decline all settlements?"),
                    React.createElement(DialogActions, null,
                        React.createElement(Button, { color: 'error', variant: 'contained', onClick: handleDeclineAll }, "Decline"),
                        React.createElement(Button, { onClick: () => setDeclineDialog(false), color: 'inherit' }, "Cancel"))))),
        React.createElement(Paper, { sx: { width: '100%' } },
            loading ? null : (React.createElement(Box, null,
                statusFilter === 'created' ? (React.createElement(React.Fragment, null, groupedSettlements.length ? (React.createElement(Box, null, groupedSettlements.map((group, index) => (React.createElement(React.Fragment, { key: group.name },
                    React.createElement(Box, { sx: {
                            backgroundColor: theme.palette.secondary.light,
                            cursor: 'pointer',
                            marginBottom: '2px',
                        }, onClick: () => handleCollapse(index) },
                        React.createElement(Box, { p: 1 },
                            React.createElement(Box, { justifyContent: 'space-between', display: 'flex' },
                                React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                    group.collapsed ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null),
                                    React.createElement(Typography, { variant: 'subtitle2' }, "Customer:"),
                                    React.createElement(Chip, { size: 'small', color: 'primary', label: group.name }),
                                    React.createElement(Typography, { variant: 'subtitle2' }, "Client:"),
                                    React.createElement(Chip, { size: 'small', color: 'primary', label: group.settlements[0].clientName })),
                                React.createElement(Button, { variant: 'contained', size: 'small', onClick: (e) => {
                                        e.stopPropagation();
                                        setSettlemntsToExecute({
                                            customerCode: group.customerCode,
                                            customerName: group.name,
                                            settlements: group.settlements,
                                        });
                                        openConfirmDialog();
                                    } }, "Execute All")))),
                    React.createElement(Box, { sx: { overflow: 'auto' }, pl: 4 },
                        React.createElement(Collapse, { in: group.collapsed },
                            React.createElement(SettlementsTableBody, { rows: group.settlements, type: statusFilter, executeSettlement: onExecuteSettlement, declineSettlement: onDeclineSettlement, onRefreshBalance: handleRefreshBalance })))))))) : (React.createElement(Alert, { severity: 'info' }, "No settlements available")))) : (React.createElement(React.Fragment, null, settlements.length ? (React.createElement(Box, { sx: { overflow: 'auto' } },
                    React.createElement(SettlementsTableBody, { rows: visibleRows, type: statusFilter, onRequestSort: handleSort, order: order, orderBy: orderBy }))) : (React.createElement(Alert, { severity: 'info' }, "No settlements available")))),
                statusFilter !== 'created' && (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25, 1000], component: 'div', count: totalItems, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage })))),
            React.createElement(ConfirmationDialog, { title: 'Execute All Settlements', content: `Are you sure you want execute all settlements for customer: ${settlemntsToExecute === null || settlemntsToExecute === void 0 ? void 0 : settlemntsToExecute.customerName}?`, open: isConfirmOpen, onClose: () => {
                    closeConfirmDialog();
                    setSettlemntsToExecute(null);
                }, onConfirm: () => {
                    onExecuteAll(settlemntsToExecute === null || settlemntsToExecute === void 0 ? void 0 : settlemntsToExecute.settlements);
                    closeConfirmDialog();
                    setSettlemntsToExecute(null);
                } }))));
};
const stateToProps = (state) => {
    var _a;
    const { selectedClient, allClients, loading } = state.clientSearch;
    const { customers, loaded } = state.customersSearch;
    const clientId = (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code;
    const custodyCurrencies = getCustodyCurrencies(state);
    return {
        selectedClient,
        customers,
        customersLoaded: loaded,
        clients: allClients,
        clientsLoading: loading,
        clientId,
        custodyCurrencies,
    };
};
export default connect(stateToProps)(SettlementsTable);
