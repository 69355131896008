import React from 'react';
import ClientSettlementsTable from '../ClientSettlements/ClientSettlements';
import { Box, Container, Typography } from '@mui/material';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class BaaderSettlements extends React.Component {
    render() {
        return (React.createElement(Box, { p: 1 },
            React.createElement(Container, { maxWidth: 'xl' },
                React.createElement(Typography, { variant: 'h6', p: 1 }, "Settlements Baader")),
            React.createElement(ClientSettlementsTable, { type: "client", clientSettlementId: getEnvSettings().baaderClientId })));
    }
}
export default BaaderSettlements;
