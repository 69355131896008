/* eslint-disable camelcase, @typescript-eslint/no-unsafe-return, no-nested-ternary, no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { PanelTitle, PanelContainer } from './styling/style';
import { ADMIN_ORDERS_FETCH_REQUEST, ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE, ADMIN_ORDERS_URL_OPTIONS_SET_UPDATE, } from '../../../redux/actionTypes/adminActionTypes';
import Permissions from '../../Layout/AdminSidebar/Permissions';
import ClientsOrdersList from './components/Clients/Orders/main';
import { getWidgetColumns } from '../../../redux/selectors/custodySelectors';
import CancelOrdersDialog from './components/Clients/Orders/CancelOrdersDialog';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/clientActionTypes';
import { ADMIN_ORDERS_CANCEL_ALL_REQUEST } from '../../../redux/actionTypes/orderBookActionTypes';
import { getClientsOptions, getSelectedClientInputValue } from '../../../redux/selectors/clientSearchSelectors';
import DLTFTableFilters from '@shared/components/filters/DLTFTableFilters';
import { FIAT_CURRENCIES } from '@shared/constants/FiatOptions';
// to do: move to actions/actions types
const pageOfItemsUpdate = (pageOfItemsAdminOrders) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders,
});
export const ordersFetch = (urlOptionsAdminOrders) => ({
    type: ADMIN_ORDERS_FETCH_REQUEST,
    urlOptionsAdminOrders,
});
export const UrlOptionsUpdate = (urlOptionsAdminOrders) => ({
    type: ADMIN_ORDERS_URL_OPTIONS_SET_UPDATE,
    urlOptionsAdminOrders,
});
function getFilters(currencies, exchanges, clients) {
    const status = {
        name: 'status',
        label: 'Status',
        multi: true,
        values: [
            { value: 'ToCancel', label: 'To Cancel' },
            { value: 'Open', label: 'Open' },
            { value: 'Filled', label: 'Filled' },
            { value: 'Rejected', label: 'Rejected' },
            { value: 'Canceled', label: 'Canceled' },
            { value: 'CanceledPartiallyFilled', label: 'Canceled Partially Filled' },
        ],
    };
    const base = {
        name: 'base',
        label: 'Base',
        multi: true,
        values: currencies.map((c) => ({ value: c.code, label: c.code.toUpperCase() })),
    };
    const exchange_code = {
        name: 'exchange_code',
        label: 'Exchange',
        values: exchanges.map((e) => ({ value: e.code, label: e.name })),
    };
    const action_type = {
        name: 'action_type',
        label: 'Action',
        values: [
            { value: 'Buy', label: 'Buy' },
            { value: 'Sell', label: 'Sell' },
            { value: null, label: 'All' },
        ],
    };
    const order_type = {
        name: 'order_type',
        label: 'Order Type',
        values: [
            { value: 'limit', label: 'Limit' },
            { value: 'market', label: 'Market' },
            { value: 'rfq_market', label: 'RFQ Market' },
            { value: 'rfq_limit', label: 'RFQ Limit' },
        ],
    };
    const from = {
        name: 'from',
        label: 'From',
        type: 'dateFrom',
    };
    const to = {
        name: 'to',
        label: 'To',
        type: 'dateTo',
    };
    const amount_from = {
        name: 'amount_from',
        label: 'Amount From',
        type: 'number',
    };
    const amount_to = {
        name: 'amount_to',
        label: 'Amount To',
        type: 'number',
    };
    const quote = {
        name: 'quote',
        label: 'Quote',
        values: FIAT_CURRENCIES,
    };
    const exclude_client_codes = {
        name: 'exclude_client_codes',
        label: 'Exclude Clients',
        values: clients,
        multi: true,
    };
    return [
        status,
        exchange_code,
        base,
        quote,
        order_type,
        action_type,
        from,
        to,
        amount_from,
        amount_to,
        exclude_client_codes,
    ];
}
const MainContainer = ({ columns, features, widgetKey, clientInfo, selectedClient, masterTenant, dispatchCancelAll, ordersFetchDispatch, dispatchPageOfItems, dispatchCloseNotice, UrlOptionsUpdateDispatch, urlOptionsAdminOrders, pageOfItemsAdminOrders, tradingCurrencies, exchanges, clients, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState([]);
    const [filtersQueryParams, setFiltersQueryParams] = useState({});
    const sortDirection = 'desc';
    const sortBy = 'created';
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleCancelAllOrders = () => {
        dispatchCancelAll(dispatchCloseNotice);
    };
    const handleFilterChange = (filters) => {
        dispatchPageOfItems(0);
        const formattedFilters = Object.assign(Object.assign({}, filters), { from: filters.from ? toStartOfDayISO(new Date(filters.from)) : undefined, to: filters.to ? toEndOfDayISO(new Date(filters.to)) : undefined });
        setFiltersQueryParams(formattedFilters);
    };
    function toEndOfDayISO(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).toISOString();
    }
    function toStartOfDayISO(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).toISOString();
    }
    const makeQueryParams = () => {
        const params = Object.assign(Object.assign({ page: (pageOfItemsAdminOrders + 1).toString(), limit: rowsPerPage.toString(), sort_by: sortBy, sort_direction: sortDirection }, filtersQueryParams), { client_code: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '' });
        const queryParams = Object.entries(params)
            .filter(([_, value]) => value && !(Array.isArray(value) && (value === null || value === void 0 ? void 0 : value.length) === 0))
            .map(([key, value]) => Array.isArray(value)
            ? `${encodeURIComponent(key)}=${value.map(encodeURIComponent).join('|')}`
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        return `?${queryParams}`;
    };
    useEffect(() => {
        if (urlOptionsAdminOrders) {
            ordersFetchDispatch(urlOptionsAdminOrders);
        }
    }, [urlOptionsAdminOrders]);
    useEffect(() => {
        if (tradingCurrencies && exchanges) {
            setFilters(getFilters(tradingCurrencies, exchanges, clients));
        }
    }, [tradingCurrencies, exchanges]);
    useEffect(() => {
        UrlOptionsUpdateDispatch(makeQueryParams());
    }, [filtersQueryParams, selectedClient, pageOfItemsAdminOrders, rowsPerPage, sortBy, sortDirection]);
    if (!clientInfo) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: '33px' })));
    }
    return (React.createElement("div", { className: 'content-body', role: 'main' },
        React.createElement(Permissions, { features: features, permissions: ['order.read'] },
            React.createElement(PanelContainer, null,
                React.createElement(PanelTitle, null,
                    React.createElement(Box, { p: 2 },
                        React.createElement(DLTFTableFilters, { fields: filters, onChange: (e) => {
                                handleFilterChange(e);
                            }, onReset: () => { handleFilterChange({}); } }))),
                React.createElement("div", { role: 'table', className: 'card-body' },
                    React.createElement(ClientsOrdersList, { columns: columns, admin: masterTenant, widgetKey: widgetKey, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage, dispatchPageOfItems: dispatchPageOfItems })),
                modalOpen ? (React.createElement(CancelOrdersDialog, { open: modalOpen, toggleFunc: toggleModal, cancelAllOrders: handleCancelAllOrders })) : null))));
};
const mapStateToProps = (state, ownProps) => ({
    widgetKey: ownProps.widgetKey,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    urlOptionsAdminOrders: state.admin.urlOptionsAdminOrders,
    pageOfItemsAdminOrders: state.admin.pageOfItemsAdminOrders,
    tradingCurrencies: state.currencies.tradingCurrencies,
    exchanges: state.placeOrder.exchanges,
    clients: getClientsOptions(state),
});
const mapDispatchToProps = (dispatch) => ({
    ordersFetchDispatch: (url) => dispatch(ordersFetch(url)),
    dispatchCloseNotice: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
    dispatchPageOfItems: (page) => dispatch(pageOfItemsUpdate(page)),
    dispatchLoadClientInfo: () => dispatch({ type: CLIENT_INFO_FETCH_REQUEST }),
    dispatchCancelAll: (dispatchCloseNotice) => dispatch({ type: ADMIN_ORDERS_CANCEL_ALL_REQUEST, dispatchCloseNotice }),
    UrlOptionsUpdateDispatch: (urlOptionsAdminOrders) => dispatch(UrlOptionsUpdate(urlOptionsAdminOrders)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
