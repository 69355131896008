/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any, no-underscore-dangle */
export const getEnvSettings = () => {
    const env = window.__env || {};
    return {
        wsUri: env.WSS_URL || 'wss://stage.dlt-finance.com/api/v1.1/ws',
        apiUrl: (env.API && `${env.API}/v1.1`) || 'https://stage.dlt-finance.com/api/v1.1',
        frontendApiUrl: (env.API && `${env.API}/f`) || 'https://stage.dlt-finance.com/api/f',
        adminApiUrl: (env.API && `${env.API}/admin`) || 'https://stage.dlt-finance.com/api/admin',
        env: env.ENVIRONMENT || 'dlt',
        thousandDivider: env.THOUSAND_DIVIDER || '\'',
        etoroClientId: env.ETORO_CLIENT_ID || 'a4db648c-562b-4c5f-a8b2-770e1b5d01af',
        nexusClientId: env.NEXUS_CLIENT_ID || '5f4d41e9-9de7-4373-bf4f-495493066d4d',
        baaderClientId: env.BAADER_CLIENT_ID || 'c72a3518-5275-4ab2-bdf1-050bd21e18bf',
        vaspDID: env.VASP_DID || 'did:ethr:0xcd83064a7ceb9057a7249d019ca8811e085b8013',
        notabeneAuthToken: env.NOTABENE_AUTH || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NkstUiJ9.eyJpYXQiOjE3Mjg0ODEyMjQsImV4cCI6bnVsbCwidmMiOnsiQGNvbnRleHQiOlsiaHR0cHM6Ly93d3cudzMub3JnLzIwMTgvY3JlZGVudGlhbHMvdjEiLCJodHRwczovL2FwaS5ub3RhYmVuZS5pZC9zY2hlbWFzL3YxIl0sInR5cGUiOlsiVmVyaWZpYWJsZUNyZWRlbnRpYWwiLCJBY2Nlc3NUb2tlbiJdLCJjcmVkZW50aWFsU3ViamVjdCI6eyJub25jZSI6ImRlOGE5NmViLTU1NDgtNDVlMC04ZWVhLTg1ODlhMjhkZjc2NyIsImV4cGlyYXRpb25EYXRlIjoxNzI4NTY3NjI0MzY4LCJzY29wZSI6ImN1c3RvbWVyIiwidmFzcF9kaWQiOiJkaWQ6ZXRocjoweGNkODMwNjRhN2NlYjkwNTdhNzI0OWQwMTljYTg4MTFlMDg1YjgwMTMifX0sInN1YiI6ImRpZDprZXk6ejZNa2lzZUNZWU1reDczV3l0V3VFcEZUOGdXeFFqY3h6OUdEY3JVblB2Rk1HTHFrIiwiaXNzIjoiZGlkOmV0aHI6MHhjZDgzMDY0YTdjZWI5MDU3YTcyNDlkMDE5Y2E4ODExZTA4NWI4MDEzIn0.HFDBLTsA9MVAgFQOTpXTmTYCe6hbHU_3JyZpf_AZyJsX3YYA1hZPdoVHL6MHoB3ct5rHkl4ELcTjmkRTAzr0qAE',
    };
};
