import axios from 'axios';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '@shared/helpers/notifications';
export const addProvider = (params) => {
    return axios
        .post('/liquidity_provider', params, { baseURL: getEnvSettings().adminApiUrl })
        .then(() => {
        showNotification({ message: 'Liquidity provider has been successfuly created', color: 'success' });
    })
        .catch(() => {
        showNotification({ message: 'Liquidity provider has failed to create', color: 'error' });
    });
};
export const fetchProviders = () => {
    return axios.get('/liquidity_provider', { baseURL: getEnvSettings().adminApiUrl });
};
