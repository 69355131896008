import { Box } from '@mui/material';
import React from 'react';
const EntityDetails = (props) => {
    const { entity } = props;
    const renderValue = (key, value) => {
        return (React.createElement(Box, { key: key, sx: { display: 'flex', flexDirection: 'column' }, pl: 1 },
            React.createElement("strong", null, key.split('')[0].toUpperCase() + key.slice(1).replace('_', ' ')),
            React.createElement("div", null, renderList(value))));
    };
    const renderList = (value) => {
        if (Array.isArray(value)) {
            return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 2 }, value.map((o) => {
                return Object.entries(o).map(([key, nestedValue]) => {
                    return renderValue(key, nestedValue);
                });
            })));
        }
        else if (typeof value === 'object' && value !== null) {
            return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 2 }, Object.entries(value).map(([key, nestedValue]) => renderValue(key, nestedValue))));
        }
        else {
            return value || 'N/A';
        }
    };
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 2 }, renderList(entity)));
};
export default EntityDetails;
