import { createSelector } from 'reselect';
/* eslint-disable @typescript-eslint/no-unsafe-call, import/prefer-default-export,
  @typescript-eslint/no-unsafe-return, no-nested-ternary */
const masterTenantApprovalAddresses = (state) => state.masterTenant.approvalsAddresses;
const masterTenantApprovalWithdrawals = (state) => state.masterTenant.approvalsWithdrawals;
export const getMasterTenantWithdrawals = createSelector(masterTenantApprovalWithdrawals, approvalsWithdrawals => approvalsWithdrawals && approvalsWithdrawals.length ? approvalsWithdrawals : []);
export const getAddressesToRender = createSelector(masterTenantApprovalAddresses, (addresses) => {
    if (addresses.length === 0) {
        return [];
    }
    return addresses.map(a => Object.assign({}, a, { wallet_label: '' }));
});
export const getWithdrawalsToRender = createSelector(masterTenantApprovalWithdrawals, (withdrawals) => {
    if (!withdrawals || withdrawals.length === 0) {
        return [];
    }
    return withdrawals.map((r) => {
        const destination = !r.destination_type ? r.address
            : r.destination_type === 'address' ? r.destination_address : r.destination_wallet;
        const address = destination && Object.prototype.hasOwnProperty.call(destination, 'address')
            ? destination.address : '';
        const convert = {
            fee: r.fee,
            usd_fee: '',
            code: r.code,
            state: r.state,
            wallet_label: '',
            customer_code: '',
            client_code: r.client_uuid,
            tx_hash: r.tx_hash,
            created: r.created_at,
            updated: r.updated_at,
            address: address || '',
            wallet_code: r.wallet_code,
            reference_id: r.reference_id,
            currency_code: r.currency_code,
            failure_reason: r.failure_reason,
            source_address: r.source_address,
            requested_amount: r.requested_amount,
            failure_raw_error: r.failure_raw_error,
            fee_currency_code: r.fee_currency_code,
            destination_type: r.destination_type || '',
            requested_amount_usd: r.requested_amount_usd,
            address_label: destination ? destination.label : '',
            replaced_withdrawal_code: r.replaced_withdrawal_code || '',
            kyt_sub_status: r.kyt_sub_status,
            travel_rule_sub_status: r.travel_rule_sub_status,
        };
        return convert;
    });
});
